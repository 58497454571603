/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import "../../Css/root.css";
import { useState} from "react";
import axios from "../../axios";
import api from "../../api";
import { useDispatch } from "react-redux";
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {DesktopDatePicker} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button';
import { useSnackbar } from "notistack"
import SubscriptionFilter from "../../Components/Filters/SubscriptionFilter";
import PaymentExportExcel from "./PaymentExportExcel";
import UserPayment from "./UserPayment";
import PaymentVisualize from "./PaymentVisualize"
import moment from "moment"
export default function Payment(props) {

  const [token, setToken] = useState(localStorage.getItem("user"));
  const [Datas, setData] = useState([]);
  const [Count, setCount] = useState();
  const [row, setRow] = useState(10);
  const [page, setpage] = useState(0);
  const [show, setShow] = React.useState(false);
  const dispatch = useDispatch();
 
  const [Datass, setDatass] = useState([]);
  const [url, seturl] = useState(api.paymentGetlist);
  var i = (page * row) + 1;
  const { enqueueSnackbar } = useSnackbar()

  const [selectedSubscription, setSelectedSubscription] = useState('')
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());

  
  const [totalAmount, setTotalAmount] = React.useState({ show: false, totalStoreAmount:0, totalPayment: 0 })

  const handleChangeStartDate = (newValue) => {
    setStartDate(newValue);
  };

  const handleChangeEndDate = (newValue) => {
    setEndDate(newValue);
  };

  let data = {
    headers: {
      "x-auth-token": token,
    },
  };

  async function my_async_fn(row, page, url) {
    axios
      .get(url + "pageNo=" + page + "&row=" + row, data)
      .then((resp) => {
        setData(resp.data.result);
        setCount(resp.data.count);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          return dispatch(props.logout());
        } else {
          return error.response.status;
        }
      });
  }
 
 

  function handleSearch() {
    if (!selectedSubscription) return enqueueSnackbar("Subscription id is not allowed to be empty", { variant: 'error'});
    const SearchUrl = api.paymentSearchByDate + `subscriptionId=${selectedSubscription}&fromDate=${moment(startDate).startOf('day').utc()}&toDate=${moment(endDate).endOf('day').utc()}&`
    seturl(SearchUrl);
    my_async_fn(row, page, SearchUrl);
  }


  function handleSearchTotalPayment(){
    
    if (!selectedSubscription) return enqueueSnackbar("Subscription id is not allowed to be empty", { variant: 'error'});

    axios
      .get(`/admin/payments/search-total-payment?subscriptionId=${selectedSubscription}&fromDate=${moment(startDate).startOf('day').utc()}&toDate=${moment(endDate).endOf('day').utc()}`, data)
      .then((resp) => {
        setTotalAmount({...resp.data.result[0], show:true})
      })
      .catch((error) => {
        return error.response.status;
      });
  }


  const subscriptionSelector = (
    <Card>
      <CardHeader title="Select Subscription" />
      <CardContent>
        <SubscriptionFilter
          selectedSubscription={selectedSubscription}
          handleSubscriptionSelection={(subscriptionId) => {
            setSelectedSubscription(subscriptionId)
          }}
        />
        <Grid sx={{ mt: 2 }} container direction="row" justifyContent="center" alignItems="center" spacing={4}>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack sx={{ width: 450 }} spacing={3}>
                <DesktopDatePicker
                  label="START DATE"
                  inputFormat="MM/dd/yyyy"
                  value={startDate}
                  onChange={handleChangeStartDate}
                  renderInput={(params) => <TextField {...params} />}
                />

                <DesktopDatePicker
                  label="END DATE"
                  inputFormat="MM/dd/yyyy"
                  value={endDate}
                  onChange={handleChangeEndDate}
                  renderInput={(params) => <TextField {...params} />}
                />

                <Button onClick={handleSearchTotalPayment} variant="contained">GET TOTAL PAYMENT</Button>

                <PaymentExportExcel
                  startDate={startDate}
                  endDate={endDate}
                  selectedSubscription={selectedSubscription}
                />

                {
                  totalAmount.totalStoreAmount && totalAmount.show ? 
                    <>
                      <Typography variant="h6" gutterBottom component="h2">
                        Total StoreAmount : {totalAmount.totalStoreAmount && totalAmount.totalStoreAmount.toFixed(3)}
                      </Typography>
                      <Typography variant="h6" gutterBottom component="h2">
                        Total Payment : {totalAmount.totalPayment && totalAmount.totalPayment.toFixed(3)}
                      </Typography>
                      <Typography variant="h6" gutterBottom component="h2">
                        Count : {totalAmount.count ? totalAmount.count : null }
                      </Typography>
                    </> : null
                }
              </Stack>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )

  return (
      <div className="col-lg-12">     
        <UserPayment />
        {subscriptionSelector}
        <PaymentVisualize/>
      </div>
  );
}

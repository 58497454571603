import React, { useEffect, useState } from "react"
import "../../Css/root.css"
import axios from "../../axios"
import api from "../../api"
import Grid from '@mui/material/Grid'
import BasicSelector from "../BasicSelector"

export default function CatalogCourseClassFilter(props) {
    // Data states
    const [goals, setGoals] = useState([])
    const [subscriptions, setSubscriptions] = useState([])
    const [courses, setCourses] = useState([])
    const [subjects, setSubjects] = useState([])
    const [chapters, setChapters] = useState([])
    const [classes, setClasses] = useState([])

    // Selection states
    const [selectedGoal, setSelectedGoal] = useState('')
    const [selectedSubscription, setSelectedSubscription] = useState('')
    const [selectedCourse, setSelectedCourse] = useState('')
    const [selectedSubject, setSelectedSubject] = useState('')
    const [selectedChapter, setSelectedChapter] = useState('')

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    // Select data props
    const goalData = {
        title: "Goals",
        items: goals,
        selected: selectedGoal
    }

    const subscriptionData = {
        title: "Subscriptions",
        items: subscriptions,
        selected: selectedSubscription
    }

    const courseData = {
        title: "Courses",
        items: courses,
        selected: selectedCourse
    }

    const subjectData = {
        title: "Subject",
        items: subjects,
        selected: selectedSubject
    }

    const chapterData = {
        title: "Chapter",
        items: chapters,
        selected: selectedChapter
    }

    const classData = {
        title: "Class",
        items: classes,
        selected: props.selectedClass
    }


    // Select elements
    const goalSelector = (
        <Grid item>
            <BasicSelector data={goalData} sx={{ width: 200 }} handleSelection={handleGoalSelection} />
        </Grid>
    )

    const courseSelector = (
        <Grid item>
            <BasicSelector data={courseData} sx={{ width: 200 }} handleSelection={handleCourseSelection} />
        </Grid>
    )

    const subjectSelector = (
        <Grid item>
            <BasicSelector data={subjectData} sx={{ width: 200 }} handleSelection={handleSubjectSelection} />
        </Grid>
    )

    const chapterSelector = (
        <Grid item>
            <BasicSelector data={chapterData} sx={{ width: 200 }} handleSelection={handleChapterSelection} />
        </Grid>
    )

    const classSelector = (
        <Grid item>
            <BasicSelector data={classData} sx={{ width: 200 }} handleSelection={handleClassSelection} />
        </Grid>
    )

    useEffect(() => {
        getGoalsForFilter();
    }, []);

    // Data fetching and state handling functions
    async function getGoalsForFilter() {
        axios
            .get(api.getFilterGoals, config)
            .then((response) => {
                setGoals(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    async function getSubscriptionsForFilter(goalId) {
        axios
            .get(api.getFilterSubscriptions + goalId, config)
            .then((response) => {
                setSubscriptions(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    async function getCoursesForFilter(goalId) {
        axios
            .get(api.getCourseExplorerCatalogCourses + goalId, config)
            .then((response) => {
                setCourses(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    async function getSubjectsForFilter(courseId) {
        axios
            .get(api.getFilterSubjects + courseId, config)
            .then((response) => {
                setSubjects(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    async function getChaptersForFilter(subjectId) {
        axios
            .get(api.getFilterChapters + subjectId, config)
            .then((response) => {
                setChapters(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    async function getClassesForFilter(chapterId) {
        axios
            .get(api.getFilterClasses + chapterId, config)
            .then((response) => {
                setClasses(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    function handleGoalSelection(goalId) {
        setSelectedGoal(goalId)
        //props.handleCourseSelection('')
        props.handleClassSelection('')
        getCoursesForFilter(goalId)

        setSelectedCourse('')
        setSelectedSubject('')
        setSelectedChapter('')
    }

    function handleSubscriptionSelection(subscriptionId) {
        setSelectedSubscription(subscriptionId)
        props.handleCourseSelection('')
        props.handleClassSelection('')
        getCoursesForFilter(subscriptionId)
        setSelectedCourse('')
        setSelectedSubject('')
        setSelectedChapter('')
    }

    function handleCourseSelection(courseId) {
        setSelectedCourse(courseId)
        props.handleCourseSelection(courseId)
        props.handleClassSelection('')
        getSubjectsForFilter(courseId)
        setSelectedSubject('')
        setSelectedChapter('')
    }

    function handleSubjectSelection(subjectId) {
        setSelectedSubject(subjectId)
        getChaptersForFilter(subjectId)
        props.handleClassSelection('')
        setSelectedChapter('')
    }

    function handleChapterSelection(chapterId) {
        setSelectedChapter(chapterId)
        getClassesForFilter(chapterId)
        props.handleClassSelection('')
    }

    function handleClassSelection(classId) {
       props.handleClassSelection(classId)
    }

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
            {goalSelector}
            {courseSelector}
            {subjectSelector}
            {chapterSelector}
            {classSelector}
        </Grid>
    )
}
import React from "react"
import "../Css/root.css"
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'

export default function MultiSelectorChip(props) {
    const index = props.data.index ? props.data.index : "title"

    return (
        <FormControl sx={props.sx} error={props.error}>
            <Autocomplete
                multiple
                disableCloseOnSelect
                value={props.data?.items.filter((x) => props.data?.selected?.includes(x._id))}
                options={props.data?.items}
                getOptionLabel={(option) => option[index]}
                renderOption={(props, option, { selected }) => (
                    <ListItemText {...props} key={option._id}>
                        <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option[index]}
                    </ListItemText>
                )}
                renderInput={(params) => (
                    <TextField {...params} required={props.required} error={props.error} label={props.data.title} />
                )}
                isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                }
                onChange={(_, newValue) =>
                    props.handleSelection(newValue.map((x) => x._id))
                }
            />
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
    )
}
import { Modal, Button } from "react-bootstrap"
import React from "react"

export function OrderDetailsModal({ OrderDetail, show, handleClose }) {

    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }

    return (

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Order Details</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Name :  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}> {OrderDetail.name}</span> </p>
                <p>Email  :  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}>{OrderDetail.email}</span> </p>
                <p>Phone :  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}> {OrderDetail.phone}</span> </p>
                <p>Wallet Amount :  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}> {OrderDetail.walletAmount}</span> </p>
                <br /><br />
                {
                    OrderDetail.subscriptions ?
                        <>
                            {
                                OrderDetail.subscriptions.map(item =>
                                    <div key={item._id}>
                                        <p>Subscription title :  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}> {item.subscriptionId.title}</span> </p>
                                        <p>Last Payment Date :  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}> {formatDate(item.lastPaymentDate)}</span>
                                        <p>End Date :  <span style={{ fontSize: "18px", fontWeight: '400', color: 'black' }}> {formatDate(item.endDate)}</span> </p> </p>
                                        <br /> <br />
                                    </div>
                                )
                            }

                        </>
                        : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
import React, { useEffect, useState } from "react"
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Switch from '@mui/material/Switch'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import RestoreIcon from '@mui/icons-material/Restore'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Stack from '@mui/material/Stack'
import BasicCircularProgress from "../../../Components/BasicCircularProgress"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useSnackbar } from "notistack"

export default function ClassAddUpdateDialog(props) {
    const [classLoading, setClassLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    const [title, setTitle] = useState('')
    const [order, setOrder] = useState('')
    const [isActive, setIsActive] = useState(true)
    const [isFree, setIsFree] = useState(false)
    const [server, setServer] = useState('')
    const [videoUrl, setVideoUrl] = useState('')
    const [classNoteUrl, setClassNoteUrl] = useState('')

    const [errorMessage, setErrorMessage] = useState({})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const chapterId = props.chapterId

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    function resetFormData() {
        setTitle('')
        setOrder('')
        setIsActive(true)
        setIsFree(false)
        setServer('')
        setVideoUrl('')
        setClassNoteUrl('')
        setErrorMessage({})
    }

    const handleClose = () => {
        props.handleClose()
    }

    const handleReset = () => (
        resetFormData()
    )

    const handleSubmit = () => {
        if(!isFormDataValid()) return
        props.classId ? updateClass() : addNewClass()
    }

    const isFormDataValid = () => {
        var isValid = true
        if(!title.length) {
            isValid = false
            setErrorMessage(message => {
                return {...message, title: 'Title must not be empty.'}
            })
        }
        if(!order) {
            isValid = false
            setErrorMessage(message => {
                return {...message, order: 'Order must be a valid number'}
            })
        }
        if(!server) {
            isValid = false
            setErrorMessage(message => {
                return {...message, server: 'Select a server.'}
            })
        }
        if(!videoUrl.length) {
            isValid = false
            setErrorMessage(message => {
                return {...message, videoUrl: 'Video URL must not be empty.'}
            })
        }
        return isValid
    }

    async function fetchClassData(classId) {
        setClassLoading(true)
        axios
            .get(api.getClassById + classId, config)
            .then((response) => {
                setTitle(response.data.title)
                setIsActive(response.data.isActive)
                setIsFree(response.data.isFree)
                if(response.data.order) setOrder(response.data.order)
                setServer(response.data.server)
                setVideoUrl(response.data.videoUrl)
                setClassNoteUrl(response.data.classNote)
                setClassLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Class data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function addNewClass() {
        setSubmitLoading(true)
        var classPayload = {
            title: title,
            order: order,
            isFree: isFree,
            isActive: isActive,
            server: server,
            videoUrl: videoUrl
        }
        if(classNoteUrl) classPayload = {...classPayload, classNote: classNoteUrl}
        axios
            .post(api.createClass, classPayload, config)
            .then((response) => {
                axios
                    .post(
                        api.addClassToChapter,
                        {
                            classId: response.data,
                            chapterId: chapterId
                        },
                        config
                    )
                    .then((response) => {
                        enqueueSnackbar(response.data, { variant: 'success' })
                        setSubmitLoading(false)
                        handleClose()
                        props.handleSuccess()
                    })
                    .catch((error) => {
                        enqueueSnackbar("Add new class failed: " + error.response.data, { variant: 'error' })
                    })
            })
            .catch((error) => {
                enqueueSnackbar("Add new class failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function updateClass() {
        var updatePayload = {
            title: title,
            order: order,
            isFree: isFree,
            isActive: isActive,
            server: server,
            videoUrl: videoUrl
        }
        if(classNoteUrl) updatePayload = {...updatePayload, classNote: classNoteUrl}
        axios
            .put(api.updateClass + props.classId, updatePayload, config)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
                handleClose()
                props.handleSuccess()
            })
            .catch((error) => {
                enqueueSnackbar("Update class failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        // TODO: Stop initial loading
        if(!props.open) {
            return
        }
        if(props.classId) fetchClassData(props.classId)
    }, [props.open])

    const appBar = (
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Add or Update Class
                </Typography>
                <LoadingButton
                    color="secondary"
                    onClick={handleSubmit}
                    loading={classLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
                <LoadingButton
                    color="inherit"
                    sx={{ ml: 2 }}
                    onClick={handleReset}
                    loading={classLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<RestoreIcon />}
                    variant="contained"
                >
                    Reset
                </LoadingButton>
            </Toolbar>
        </AppBar>
    )

    const titleField = (
        <TextField
            required
            error={!!errorMessage.title}
            variant="outlined"
            label="Title"
            helperText={errorMessage.title}
            sx={{ minWidth: 300 }}
            value={title}
            onChange={(event) => {
                const text = event.target.value
                if(!text.length) {
                    setErrorMessage(message => {
                        return {...message, title: 'Title must not be empty.'}
                    })
                } else {
                    setErrorMessage(message => {
                        return {...message, title: ''}
                    })
                }
                setTitle(event.target.value)
            }}
        />
    )

    const orderField = (
        <TextField
            required
            error={!!errorMessage.order}
            variant="outlined"
            type="number"
            label="Order"
            helperText={errorMessage.order}
            sx={{ minWidth: 300 }}
            value={order}
            onChange={(event) => {
                const order = event.target.value
                if(!order) {
                    setErrorMessage(message => {
                        return {...message, order: 'Order must be a valid number.'}
                    })
                } else {
                    setErrorMessage(message => {
                        return {...message, order: ''}
                    })
                }
                setOrder(event.target.value)
            }}
        />
    )

    const isActiveSwitch = (
        <FormControlLabel
            label="Is Active"
            control={
                <Switch
                    checked={isActive}
                    onChange={(event) => setIsActive(event.target.checked)}
                />
            }
        />
    )

    const isFreeSwitch = (
        <FormControlLabel
            label="Is Free"
            control={
                <Switch
                    checked={isFree}
                    onChange={(event) => setIsFree(event.target.checked)}
                />
            }
        />
    )

    const serverSelector = (
        <FormControl required sx={{minWidth: 300}} error={!!errorMessage.server}>
            <InputLabel>Server</InputLabel>
            <Select
                label="Server"
                value={server}
                onChange={(event) => {
                    event.preventDefault()
                    setErrorMessage(message => {
                        return {...message, server: ''}
                    })
                    setServer(event.target.value)
                }}
            >
                <MenuItem key="youtube" value="youtube">Youtube</MenuItem>
                <MenuItem key="vimeo" value="vimeo">Vimeo</MenuItem>
            </Select>
            <FormHelperText>{errorMessage.server}</FormHelperText>
        </FormControl>
    )

    const videoUrlField = (
        <TextField
            required
            variant="standard"
            label="Video URL"
            sx = {{ minWidth: 400 }}
            error={!!errorMessage.videoUrl}
            helperText={errorMessage.videoUrl}
            value={videoUrl}
            onChange={(event) => {
                const url = event.target.value
                if (!url.length) {
                    setErrorMessage(message => {
                        return { ...message, videoUrl: 'Video URL must not be empty.' }
                    })
                } else {
                    setErrorMessage(message => {
                        return { ...message, videoUrl: '' }
                    })
                }
                setVideoUrl(url)
            }}
        />
    )

    const classNoteUrlField = (
        <TextField
            variant="standard"
            label="Class Note URL"
            sx = {{ minWidth: 400 }}
            value={classNoteUrl}
            onChange={(event) => {
                setClassNoteUrl(event.target.value)
            }}
        />
    )

    return (
        <Dialog fullScreen disableEnforceFocus open={Boolean(props.open)} onClose={handleClose}>
            {appBar}
            {classLoading && <BasicCircularProgress />}
            <Box sx={{ m: 2 }}>
                <Stack direction="column" spacing={4}>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                        {titleField}
                        {orderField}
                    </Stack>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                        {isActiveSwitch}
                        {isFreeSwitch}
                    </Stack>
                    <Stack direction="column" justifyContent="center" alignItems="center" spacing={4}>
                        {serverSelector}
                        {videoUrlField}
                        {classNoteUrlField}
                    </Stack>
                </Stack>
            </Box>
        </Dialog>
    )
}
import React, { useState } from 'react'
import { useSnackbar } from "notistack"

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from "@mui/material/TextField"

import {DesktopDatePicker} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
//import SubscriptionMultiSelector from "./SubscriptionMultiSelector"
import SubscriptionMultiSelect from "../../../Components/Filters/SubscriptionsMutliSelect/MultiSelect"
import ExcelToUploadPhone from "./ExcelToUploadPhone"
import RejectedBrandPartnerUserExportExcel from './RejectedBrandPartnerUserExportExcel'
import axios from "../../../axios"
import api from "../../../api"

export default function CreateBrandPartner() {

    const [rejectedUser, setRejectedUser] = React.useState([])
    const [formData, setFormData] = React.useState(
        {
            users: [],
            coupons: [],
            subscriptions: [],
            percentDiscount: 0,
            referralPercentage : 0,
            flatDiscount: 0,
            expiredDate: new Date(),
            isActive: true
        })

    const { enqueueSnackbar } = useSnackbar()
    const config = { headers: { "x-auth-token": localStorage.getItem("user") } }

    const handleMergeUser = (users, coupons) => { setFormData({ ...formData, users: users, coupons: coupons }) }
    const handleChangeSubscriptions = (subscriptions) => { setFormData({ ...formData, subscriptions: subscriptions }) }
    const handleSubmit = async () => {
        if (formData.users.length == undefined ||formData.users?.length <= 0) return enqueueSnackbar("User is not allowed to be empty", { variant: "warning" })
        if (formData.coupons.length == undefined || formData.coupons?.length <= 0) return enqueueSnackbar("Coupon is not allowed to be empty", { variant: "warning" })
        if (formData.subscriptions.length ==undefined || formData.subscriptions?.length <= 0) return enqueueSnackbar("Subscription is not allowed to be empty", { variant: "warning" })
        if (formData.percentDiscount < 0) return enqueueSnackbar("Percentdiscount is not allowed to be less than zero", { variant: "warning" })
        if (formData.flatDiscount < 0) return enqueueSnackbar("Flatdiscount is not allowed to be less than zero", { variant: "warning" })
        if (!formData.expiredDate) return enqueueSnackbar("Expiredate is not allowed to be empty", { variant: "warning" })
        const subs_Ids = formData.subscriptions.map(function (item) { return item._id })
        try {
            const response = await axios.post(api.setBrandPartnerAndCreateCouponfromExcel, { ...formData, subscriptions : subs_Ids }, config)
            const { rejectedUser } = response.data
            if (rejectedUser?.length > 0) {
                enqueueSnackbar("Some coupon creation is failed", { variant: "warning" })
                setRejectedUser(rejectedUser)
            } else {
                enqueueSnackbar("All coupns and brand partner successfully created", { variant: "success" })
            }
            setFormData({
                users: [],
                coupons: [],
                subscriptions: [],
                percentDiscount: 0,
                referralPercentage : 0,
                flatDiscount: 0,
                expiredDate: new Date(),
                isActive: true
            })
        } catch (error) {
            enqueueSnackbar(""+error.response.data, { variant: "error" })
        }
    }

    const rejectedUserUi = (
        <Paper variant="outlined" sx={{ m: 2, p: 2, width: { md: 600, xs: 300 } }} square>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={'4'}
                sx={{maxHeight : '700px', overflowY : 'auto'}}
            >
                <List>
                    {rejectedUser?.map((item, index) =>
                        <ListItem key={index}>
                            <ListItemIcon>
                                <ContactPhoneIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={`Phone Number: ${item}`}
                            />
                        </ListItem>,
                    )}
                </List>
            </Grid>
        </Paper>
    )

    const form = (
        <Paper variant="outlined" sx={{ m: 2, p: 4, width: { md: 600, xs: 300 } }} square>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                rowSpacing={4}
                columnSpacing={4}
            >
                <Grid item md={6} xs={12}>
                    <TextField
                        sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                        required
                        name="percentDiscount"
                        value={formData.percentDiscount}
                        onChange={(e) => setFormData({ ...formData, percentDiscount: e.target.value })}
                        type='number'
                        id="outlined-required"
                        label="Percent discount"
                        defaultValue={0}
                    />
                </Grid>

                <Grid item md={6} xs={12}>
                    <TextField
                        sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                        required
                        type='number'
                        value={formData.flatDiscount}
                        name="flatDiscount"
                        onChange={(e) => setFormData({ ...formData, flatDiscount: e.target.value })}
                        id="outlined-required"
                        label="Flat discount"
                        defaultValue={0}
                    />
                </Grid>

                <Grid item md={6} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Expire date"
                            value={formData.expiredDate}
                            minDate={new Date('2017-01-01')}
                            onChange={(newValue) => {
                                setFormData({ ...formData, expiredDate: newValue })
                            }}
                            renderInput={(params) => <TextField sx={{ display: 'flex', justifyContent: 'center', width: '100%' }} {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item md={6} xs={12}>
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="isActive"
                        value={formData.isActive}
                        onChange={(e) => setFormData({ ...formData, isActive: e.target.value })}
                        sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                    >
                        <MenuItem value={true}>
                            true
                        </MenuItem>
                        <MenuItem value={false}>
                            false
                        </MenuItem>
                    </TextField>
                </Grid>

                <Grid item md={6} xs={12}>
                    <TextField
                        sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}
                        required
                        name="referralPercentage"
                        value={formData.referralPercentage}
                        onChange={(e) => setFormData({ ...formData, referralPercentage: e.target.value })}
                        type='number'
                        id="outlined-required"
                        label="Referral Percentage"
                        defaultValue={0}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <SubscriptionMultiSelect handleChangeSubscriptions={(subscriptions) => handleChangeSubscriptions(subscriptions)} />
                </Grid>

                <Grid item md={12}>
                    <ExcelToUploadPhone handleMergeUser={(users, coupons) => handleMergeUser(users, coupons)} />
                </Grid>

                <Grid sx={{ display: 'flex', justifyContent: 'center', width: '100%' }} item md={12}>
                    <Button onClick={handleSubmit} sx={{ width: '95%', color: 'white', textTransform: 'none' }} variant="contained">Submit</Button>
                </Grid>

            </Grid>
        </Paper>
    )

    return (
        <Box elevation={2} component={Paper} sx={{ p: 2 }}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Typography sx={{ color: '#0000009e' }} fontWeight={800} variant='h5' gutterBottom align='center'>Create brand partner</Typography>
                {form}
            </Grid>

            {rejectedUser?.length > 0 &&
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography sx={{ color: 'red' }} fontWeight={800} variant='h5' gutterBottom align='center'>
                        Rejected User list
                    </Typography>
                    {rejectedUserUi}
                    <Grid item md={12}>
                      <RejectedBrandPartnerUserExportExcel rejectedUser={rejectedUser} />
                    </Grid>
                </Grid>
            }
        </Box>
    )
}

import React, { useEffect, useRef, useState } from "react"
import { Editor } from "@tinymce/tinymce-react"

/* @MUI COMPONENT */
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import RestoreIcon from '@mui/icons-material/Restore'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Stack from '@mui/material/Stack'
import {DateTimePicker} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSnackbar } from "notistack"

/* @CUSTOM COMPONENT */
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import BasicCircularProgress from "../../../Components/BasicCircularProgress"

export default function WrittenExamAddUpdateDialog(props) {
    const [examLoading, setExamLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    const [title, setTitle] = useState('')
    const [order, setOrder] = useState('')
    const [isActive, setIsActive] = useState(true)
    const [isFree, setIsFree] = useState(false)
    const [noOfQuestion, setNoOfQuestion] = useState('')
    const [marksPerQuestion, setMarksPerQuestion] = useState('')
    const [questions, setQuestions] = useState([])
    const [durationInSec, setDurationInSec] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')

    const [errorMessage, setErrorMessage] = useState({})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const refArray = useRef([]);

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    const handleClose = () => {
        props.handleClose()
    }

    const handleSubmit = () => {
        if (!isFormDataValid()) return
        props.writtenExamId ? updateExam() : addNewExam()
    }

    const handleReset = () => {
        resetFormData()
    }

    const isFormDataValid = () => {
        var isValid = true
        if (!title.length) {
            isValid = false
            setErrorMessage(message => {
                return { ...message, title: 'Title must not be empty.' }
            })
        }
        if (!order) {
            isValid = false
            setErrorMessage(message => {
                return { ...message, order: 'Order must be a valid number' }
            })
        }
        if (!noOfQuestion) {
            isValid = false
            setErrorMessage(message => {
                return { ...message, noOfQuestion: 'Must be a valid number' }
            })
        }
        if (!marksPerQuestion) {
            isValid = false
            setErrorMessage(message => {
                return { ...message, marksPerQuestion: 'Must be a valid number' }
            })
        }
        if (!durationInSec) {
            isValid = false
            setErrorMessage(message => {
                return { ...message, durationInSec: 'Must be a valid number' }
            })
        }

        return isValid
    }
    const filePickerCallBack = (editor, cb) => {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {

                var id = 'blobid' + (new Date()).getTime();
                var blobCache = editor.editorUpload.blobCache;
                var base64 = reader.result.split(',')[1];
                var blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                /* call the callback and populate the Title field with the file name */
                cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
        };

        input.click();
    }


    function resetFormData() {
        setTitle('')
        setOrder('')
        setIsActive(true)
        setIsFree(false)
        setNoOfQuestion('')
        setMarksPerQuestion('')
        setDurationInSec('')
    }

    async function fetchExamData(writtenExamId) {
        setExamLoading(true)
        axios
            .get(api.getExamExplorerWrittenExamById + writtenExamId, config)
            .then((response) => {
                setTitle(response.data.title)
                setIsActive(response.data.isActive)
                setIsFree(response.data.isFree)
                setStartTime(response.data.startTime)
                if(response.data.endTime) setEndTime(response.data.endTime)
                if(response.data.order) setOrder(response.data.order)
                if(response.data.noOfQuestion) setNoOfQuestion(response.data.noOfQuestion)
                if(response.data.marksPerQuestion) setMarksPerQuestion(response.data.marksPerQuestion)
                if(response.data.durationInSec) setDurationInSec(response.data.durationInSec)
                if(response.data.questions) setQuestions(response.data.questions)
                setExamLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Exam data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function addNewExam() {
        setSubmitLoading(true)
        let examPayload = {
            title: title,
            order: order,
            course: props.courseId,
            subject: props.subjectId,
            isFree: isFree,
            isActive: isActive,
            noOfQuestion: noOfQuestion,
            marksPerQuestion: marksPerQuestion,
            durationInSec: durationInSec,
            startTime: startTime,
            endTime: endTime,
            questions : []
        }
        for (var i = 0; i < noOfQuestion; i++) {
            examPayload.questions.push({ question: refArray.current[i].getContent(), order: i + 1 })
        }
        axios
            .post(api.examExplorerNewWrittenExam, examPayload, config)
            .then((response) => {
                setSubmitLoading(false)
                handleClose()
                props.handleSuccess()
            })
            .catch((error) => {
                enqueueSnackbar("Add new exam failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function updateExam() {
        setSubmitLoading(true)
        let examPayload = {
            title: title,
            order: order,
            course: props.courseId,
            subject: props.subjectId,
            isFree: isFree,
            isActive: isActive,
            noOfQuestion: noOfQuestion,
            marksPerQuestion: marksPerQuestion,
            durationInSec: durationInSec,
            startTime: startTime,
            endTime: endTime,
            questions : []
        }
        for (var i = 0; i < noOfQuestion; i++) {
            examPayload.questions.push({ question: refArray.current[i].getContent(), order: i + 1 })
        }
        axios
            .put(api.examExplorerUpdateWrittenExam + props.writtenExamId, examPayload, config)
            .then((response) => {
                setSubmitLoading(false)
                enqueueSnackbar(response.data, { variant: 'success' })
                handleClose()
                props.handleSuccess()
            })
            .catch((error) => {
                enqueueSnackbar("Update exam failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        // TODO: Stop initial loading
        if (!props.open) {
            return
        }
        if (props.writtenExamId) {
            fetchExamData(props.writtenExamId)
        }
    }, [props.open])

    const appBar = (
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Add or Update Written Exam
                </Typography>
                <LoadingButton
                    color="secondary"
                    onClick={handleSubmit}
                    loading={examLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
                <LoadingButton
                    color="inherit"
                    sx={{ ml: 2 }}
                    onClick={handleReset}
                    loading={examLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<RestoreIcon />}
                    variant="contained"
                >
                    Reset
                </LoadingButton>
            </Toolbar>
        </AppBar>
    )

    const titleField = (
        <TextField
            required
            error={!!errorMessage.title}
            variant="outlined"
            label="Title"
            helperText={errorMessage.title}
            sx={{ minWidth: 300 }}
            value={title}
            onChange={(event) => {
                const text = event.target.value
                if (!text.length) {
                    setErrorMessage(message => {
                        return { ...message, title: 'Title must not be empty.' }
                    })
                } else {
                    setErrorMessage(message => {
                        return { ...message, title: '' }
                    })
                }
                setTitle(event.target.value)
            }}
        />
    )

    const orderField = (
        <TextField
            required
            error={!!errorMessage.order}
            variant="outlined"
            type="number"
            label="Order"
            helperText={errorMessage.order}
            sx={{ minWidth: 300 }}
            value={order}
            onChange={(event) => {
                const order = event.target.value
                if (!order) {
                    setErrorMessage(message => {
                        return { ...message, order: 'Order must be a valid number.' }
                    })
                } else {
                    setErrorMessage(message => {
                        return { ...message, order: '' }
                    })
                }
                setOrder(event.target.value)
            }}
        />
    )

    const isActiveSwitch = (
        <FormControlLabel
            label="Is Active"
            control={
                <Switch
                    checked={isActive}
                    onChange={(event) => setIsActive(event.target.checked)}
                />
            }
        />
    )

    const isFreeSwitch = (
        <FormControlLabel
            label="Is Free"
            control={
                <Switch
                    checked={isFree}
                    onChange={(event) => setIsFree(event.target.checked)}
                />
            }
        />
    )

    const noOfQuestionField = (
        <TextField
            required
            error={!!errorMessage.noOfQuestion}
            variant="outlined"
            type="number"
            label="Total Questions"
            helperText={errorMessage.noOfQuestion}
            sx={{ minWidth: 300 }}
            value={noOfQuestion}
            onChange={(event) => {
                const noOfQuestion = event.target.value
                if (!noOfQuestion) {
                    setErrorMessage(message => {
                        return { ...message, noOfQuestion: 'Total questions must be a valid number.' }
                    })
                } else {
                    setErrorMessage(message => {
                        return { ...message, noOfQuestion: '' }
                    })
                }
                setNoOfQuestion(event.target.value)
            }}
        />
    )

    const marksPerQuestionField = (
        <TextField
            required
            error={!!errorMessage.marksPerQuestion}
            variant="outlined"
            type="number"
            label="Marks per Question"
            helperText={errorMessage.marksPerQuestion}
            sx={{ minWidth: 300 }}
            value={marksPerQuestion}
            onChange={(event) => {
                const marksPerQuestion = event.target.value
                if (!marksPerQuestion) {
                    setErrorMessage(message => {
                        return { ...message, marksPerQuestion: 'Must be a valid number.' }
                    })
                } else {
                    setErrorMessage(message => {
                        return { ...message, marksPerQuestion: '' }
                    })
                }
                setMarksPerQuestion(event.target.value)
            }}
        />
    )

    const durationInSecField = (
        <TextField
            required
            error={!!errorMessage.durationInSec}
            variant="outlined"
            type="number"
            label="Duration(sec)"
            helperText={errorMessage.durationInSec}
            sx={{ minWidth: 300 }}
            value={durationInSec}
            onChange={(event) => {
                const durationInSec = event.target.value
                if (!durationInSec) {
                    setErrorMessage(message => {
                        return { ...message, durationInSec: 'Must be a valid number.' }
                    })
                } else {
                    setErrorMessage(message => {
                        return { ...message, durationInSec: '' }
                    })
                }
                setDurationInSec(event.target.value)
            }}
        />
    )

    const startTimePicker = (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                label="Starting Time"
                value={startTime}
                onChange={(newValue) => setStartTime(newValue)}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        required
                        sx={{ minWidth: 300 }}
                    />
                }
            />
        </LocalizationProvider>
    )

    const endTimePicker = (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                label="Ending Time"
                value={endTime}
                onChange={(newValue) => setEndTime(newValue)}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        sx={{ minWidth: 300 }}
                    />
                }
            />
        </LocalizationProvider>
    )
    var questionElem = []
    for (var i = 0; i < noOfQuestion; i++) {
        questionElem.push(i)
    }
    return (
        <Dialog fullScreen disableEnforceFocus open={Boolean(props.open)} onClose={handleClose}>
            {appBar}
            {(examLoading || submitLoading) ? <BasicCircularProgress /> :
                <Box sx={{ m: 2 }}>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={8}>
                        <Stack direction="column" spacing={4}>
                            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                                {titleField}
                                {orderField}
                            </Stack>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                {isActiveSwitch}
                                {isFreeSwitch}
                            </Stack>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                {noOfQuestionField}
                                {marksPerQuestionField}
                            </Stack>
                            <Stack direction="row" justifyContent="start" alignItems="center" spacing={4}>
                                {durationInSecField}
                            </Stack>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                {startTimePicker}
                                {endTimePicker}
                            </Stack>

                            {noOfQuestion &&
                                questionElem.map((item, index) =>
                                    <Stack key={index} direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                        <Editor
                                            tinymceScriptSrc="https://cdn.rootsedulive.com/scripts/js/tinymce/tinymce.min.js"
                                            onInit={(evt, editor) => (refArray.current[index] = editor)}
                                            initialValue={
                                                questions?.length > 0 && questions?.length >= index ? questions[index]?.question :  ""
                                            }
                                            init={{
                                                height: 350,
                                                width: 550,
                                                menubar: false,
                                                automatic_uploads: false,
                                                file_picker_types: 'image',
                                                file_picker_callback: (cb, _value, _meta) => filePickerCallBack(refArray.current[index], cb),
                                                plugins: [
                                                    "advlist autolink lists link image charmap print preview anchor",
                                                    "searchreplace visualblocks code fullscreen",
                                                    "insertdatetime media table paste code help wordcount",
                                                ],
                                                external_plugins: {
                                                    tiny_mce_wiris:
                                                        "https://cdn.jsdelivr.net/npm/@wiris/mathtype-tinymce4@7.24.0/plugin.min.js",
                                                },
                                                toolbar:
                                                    "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image",
                                                content_style:
                                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                            }}
                                        />
                                    </Stack>)
                            }
                        </Stack>
                    </Stack>
                </Box>}
        </Dialog>
    )
}

import React, { useState } from 'react'
import Grid from "@mui/material/Grid"
import SubjectFilter from '../../Components/Filters/SubjectFilter'

export default function DoubtFilterCourseWise(props) {

    const [selectedCourse, setSelectedCourse] = useState('')
    const [selectedSubject, setSelectedSubject] = useState('')

    function handleCourseSelection(courseId) {
        setSelectedCourse(courseId)
        props.handleCourseSelection(courseId)
        setSelectedSubject('')
    }

    function handleSubjectSelection(subjectId) {
        setSelectedSubject(subjectId)
        props.handleSubjectIdSelection(subjectId)
    }

    const subjectSelector = (
        <SubjectFilter
            selectedSubject={selectedSubject}
            handleCourseSelection={(courseId) => {
                handleCourseSelection(courseId)
            }}
            handleSubjectSelection={(subjectId) => {
                handleSubjectSelection(subjectId)
            }}
        />
    )

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid sx={{ m: 1 }} md={12} xs={12}>
                    {subjectSelector}
                </Grid>

            </Grid>
        </React.Fragment>
    )
}

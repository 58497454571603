import React from "react";
import "../Css/root.css";
import { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import axios from "../axios";
import api from "../api";
import { Link, Redirect } from "react-router-dom";
import { Form } from "react-bootstrap";
import {actions} from "../Components/Toaster/ToastReducer";
import {useDispatch } from "react-redux";

export default function UpdateBrandPartner(state) {
const dispatch = useDispatch();
const [Percentege, setPercentege] = useState(state.location.state.referral ? state.location.state.referral.referralPercentage : null)
const [token, settoken] = React.useState(localStorage.getItem("user"));
const optionsC =[]
const [Datas, setDatas] = useState([]);
const [Code, setCode] = useState(state.location.state.referral ? state.location.state.referral.code._id : '')

let data = {
    headers: {
        "x-auth-token": token,
    },
};
const [tohome, setTohome] = React.useState(false);

async function my_async_fn() {
    settoken(localStorage.getItem("user"));
    const response = await axios.get(api.getCouponBrandPartner, data);
    setDatas(response.data);
}
function sortData() {
    Datas.map((data) => optionsC.push({ label: data.code, value: data._id }));
}
function handleSubmit() {

	const body = {
		referral: {
			code: Code,
			referralPercentage: Percentege
		}
	};

    axios
        .put(api.setBrandpartnerCoupon + state.history.location.state._id, body, data)
        .then((resp) => {
			if(resp.status= 200){
				dispatch(actions.Success("Brand partner updated successfully"));
			}
            setTohome(true)
        });
}
useEffect(() => {
    settoken(localStorage.getItem("user"));
    my_async_fn();
}, []);
sortData();
if (tohome === true) {
    return <Redirect to="/brandpartner" />;
}
	return (
		<div>
			<div className="d-flex flex-column-fluid">
				<div className=" container ">
					<div className="row">
						<div className="col-md-12">
							<div className="card card-custom">
								<div className="card-header">
									<h3 className="card-title"> Edit Brand Partner</h3>
									<div className="card-toolbar">
										<div className="example-tools justify-content-center">
											<Link
												className="btn btn-success font-weight-bolder font-size-sm"
												to="/brandpartner"
											>
												<i className="flaticon2-list"></i>
												All Brand Partner
											</Link>
										</div>
									</div>
								</div>
								<form className="form">
									<input type="hidden" />
									<div className="card-body">
										<div className="form-group row">
											<label className="col-lg-2 col-form-label text-left">
												Code:
											</label>
											<div className="col-lg-3">
												<Form.Control
													as="select"
													onChange={(e) => {
														const selected = e.target.value;
														setCode(selected)
													}}
												>

													<option value={state.location.state.referral ? state.location.state.referral.code._id : null} defaultChecked>
														{state.location.state.referral ? state.location.state.referral.code.code : null}
													</option>
													{
														optionsC.map((details) => (
															<option value={details.value}>
																{details.label}
															</option>
														))
													}

												</Form.Control>
											</div>
											<label className="col-lg-2 col-form-label text-left">
												Referral Percentege
											</label>
											<div className="col-lg-3">
                                            <input
													type="text"
													value={Percentege}
													className="form-control "
													placeholder="Enter Percentege"
													onChange={(e) => {
														setPercentege(e.target.value);
													}}
												/>
											</div>
										</div>
									
							
										<div className="form-group row">
											<p>{Error}</p>
											<label
												n
												className="col-lg-2 col-form-label text-left"
												style={{ color: "red", fontSize: "22px" }}
											></label>
											<div className="col-lg-3" hidden>
												<span className="switch switch-icon switch-success ">
													<label>
														<input type="hidden" value="0" name="is_active" />
														<input type="checkbox" value="1" name="is_active" />

														<span></span>
													</label>
												</span>
											</div>
										</div>
										<div className="form-group row"></div>
									</div>
									<div className="card-footer">
										<div className="row">
											<div className="col-lg-2"></div>
											<div className="col-lg-10">
												<button
													type="button"
													className="btn btn-success mr-2"
													onClick={handleSubmit}
												>
													Submit
												</button>
												<button type="reset" className="btn btn-secondary">
													Reset
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import "../../Css/root.css";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import api from "../../api";
import TablePagination from "@mui/material/TablePagination";
import DeleteModal from "../../Components/DeleteModal";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import moment from 'moment';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {DateTimePicker} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import Grid from '@mui/material/Grid'
import { useSnackbar } from "notistack";

export default function LiveClass(props) {

  const [token, settoken] = useState(localStorage.getItem("user"));
  const [Datas, setData] = useState([]);
  const [Count, setCount] = useState();
  const [row, setRow] = useState(10);
  const [page, setpage] = useState(0);
  const dispatch = useDispatch();
  const [modal, setModal] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  const [sub, setsub] = useState([]);
  const [cors, setcors] = useState([]);
  const [searchSub, setsearchSub] = useState("");
  const [Datass, setDatass] = useState([]);
  const [url, seturl] = useState(api.liveClassGet);
  const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const [subj, setsubj] = useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const [selectedCourseId, setSelectedCourseId] = React.useState('')
  const [selectedSubjectId, setSelectedSubjectId] = React.useState('')

  const { enqueueSnackbar } = useSnackbar()

  var i = (page * row) + 1;
  let data = {
    headers: {
      "x-auth-token": token,
    },
  };

  const handleJoinMeeting = async (meetingId, liveClassId) => {
    try {
      const response = await axios.get(
        `/admin/liveClass-room/join-meeting?roomId=${meetingId}&liveClassId=${liveClassId}`,
        data
      );
      window.location.replace(`${response.data.data}`);
    } catch (error) {
      enqueueSnackbar(error?.response?.data, { variant: "error" });
    }
  };

  async function my_async_fn(row, page, url) {
    axios
      .get(url + "pageNo=" + page + "&row=" + row, data)
      .then((resp) => {
        setData(resp.data.result);
        setCount(resp.data.count);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          return dispatch(props.logout());
        } else {
          return error.response.status;
        }
      });
  }

  const handleFilterSearch = async () => {
    try {
      let filterUrl = api.liveClassFilter + `?pageNo=${page}&row=${row}`;
      if (selectedCourseId) {
        filterUrl += `&courseId=${selectedCourseId}`
      }
      if (selectedSubjectId) {
        filterUrl += `&subjectId=${selectedSubjectId}`
      }
      if (startDate && endDate) {
        filterUrl += `&fromDate=${moment(startDate).utc()}&toDate=${moment(endDate).utc()}`
      }
      const response = await axios.get(filterUrl, data)
      setData(response.data.result);
      setCount(response.data.count);
      seturl(filterUrl)
    } catch (error) {
      enqueueSnackbar("" + error.response.data, { variant: 'error' })
    }
  }

  // const handleChangeStartDate = (newValue) => {
  //   setStartDate(newValue);
  // };

  // const handleChangeEndDate = (newValue) => {
  //   setEndDate(newValue);
  // };

  const handleChangePage = (event, newPage) => {
    setpage(newPage);
    my_async_fn(row, newPage, url);
  };
  const handleChangeRowsPerPage = (event) => {
    setRow(event.target.value);
    setpage(0);
    forceUpdate();
    my_async_fn(event.target.value, 0, url);
  };

  useEffect(() => {
    my_async_fn(row, page, url);
    goals();
  }, []);

  function handleSearch() {
    seturl(api.liveClassSearchByTitle + "title=" + searchSub + "&");
    let url = api.liveClassSearchByTitle + "title=" + searchSub + "&";
    my_async_fn(row, page, url);
  }
  async function goals() {
    settoken(localStorage.getItem("user"));
    const response = await axios.get(api.getFilterGoals, data);
    setDatass(response.data);
  }

  async function getSubs(selected) {
    const response = await axios.get(api.getFilterSubscriptions + selected, data);
    setsub(response.data);
    setcors([])
    setsubj([])
  }
  async function getcourses(selected) {
    const response = await axios.get(api.getFilterCourses + selected, data);
    setcors(response.data);
    setsubj([])
  }
  async function getsubject(selected) {
    const response = await axios.get(api.getFilterSubjects + selected, data);
    setsubj(response.data);
  }

  const liveclassFilter = (
    <Card sx={{ boxShadow: 'none', mt: -4, mb: 4 }}>
      <CardContent>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                {/* <DateTimePicker
                  label="START DATE"
                  inputFormat="MM/dd/yyyy"
                  value={startDate}
                  onChange={handleChangeStartDate}
                  renderInput={(params) => <TextField {...params} />}
                /> */}
                 <DatePicker timeIntervals ={1} placeholderText="Start Date" showTimeSelect selected={startDate} onChange={(date) => setStartDate(date)} />

                {/* <DateTimePicker
                  label="END DATE"
                  inputFormat="MM/dd/yyyy"
                  value={endDate}
                  onChange={handleChangeEndDate}
                  renderInput={(params) => <TextField {...params} />}
                /> */}
                  <DatePicker timeIntervals ={1} placeholderText="End Date" showTimeSelect selected={endDate} onChange={(date) => setEndDate(date)} />

                <Button onClick={handleFilterSearch} sx={{ textTransform: 'none' }} variant="contained">Search</Button>
              </Stack>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )

  return (
    <div>
      <div>
        <div className="card card-custom">
          <div className="card-header border-0 py-5 cent">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Recorded Video Search
              </span>
            </h3>
          </div>
          <div></div>
          <div className="card-body pt-0 pb-3 ">
            <div className="form-group row">
              <div className="col-lg-12">
                <form>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div
                      className="form-group row"
                      style={{ marginBottom: "0px !important" }}
                    >
                      <div className="col-md-2"></div>

                      <div className="col-md-2">
                        <Form.Control
                          as="select"
                          onChange={(e) => {
                            const selected = e.target.value;
                            setSelectedCourseId("");
                            setSelectedSubjectId("");
                            getSubs(selected);
                          }}
                        >
                          <option value="" defaultChecked>
                            Select Goal
                          </option>
                          {Datass.map((details) => (
                            <option value={details._id}>{details.title}</option>
                          ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-2">
                        <Form.Control
                          as="select"
                          onChange={(e) => {
                            const selected = e.target.value;
                            setSelectedCourseId("");
                            setSelectedSubjectId("");
                            getcourses(selected);
                          }}
                        >
                          <option value="" defaultChecked>
                            Select Subscription
                          </option>
                          {sub.map((details) => (
                            <option value={details._id}>{details.title}</option>
                          ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-2">
                        <Form.Control
                          as="select"
                          onChange={(e) => {
                            const selected = e.target.value;
                            setSelectedCourseId(selected);
                            getsubject(selected);
                          }}
                        >
                          <option value="" defaultChecked>
                            Select Courses
                          </option>
                          {cors.map((details) => (
                            <option value={details._id}>{details.title}</option>
                          ))}
                        </Form.Control>
                      </div>
                      <div className="col-md-2">
                        <Form.Control
                          as="select"
                          onChange={(e) => {
                            const selected = e.target.value;
                            setSelectedSubjectId(selected);
                          }}
                        >
                          <option value="" defaultChecked>
                            Select Subject
                          </option>
                          {subj.map((details) => (
                            <option value={details._id}>{details.title}</option>
                          ))}
                        </Form.Control>
                      </div>

                      <div className="col-md-12 my-4 text-center"></div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="col-lg-12">
                <form className="custom-form">
                  <div className="input-group col-lg-6 col-md-6 col-sm-6 cent">
                    {liveclassFilter}
                  </div>
                </form>
              </div>

              <div className="col-lg-12">
                <form className="custom-form">
                  <div className="input-group col-lg-6 col-md-6 col-sm-6 cent">
                    <input
                      type="text"
                      id="search"
                      value={searchSub}
                      className="form-control"
                      placeholder="Search By title"
                      onKeyPress={(e) =>
                        e.key === "Enter" && e.preventDefault()
                      }
                      onChange={(e) => {
                        setsearchSub(e.target.value);
                      }}
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="btn btn-sm btn-success"
                        type="button"
                        onClick={handleSearch}
                      >
                        Search!
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        {/* Search Ends */}
      </div>

      <DeleteModal
        detail={modal}
        url={api.liveClassDelete}
        showDelete={showDelete}
        handleCloseDelete={handleCloseDelete}
        reset={() => {
          my_async_fn(row, page, url);
        }}
      />

      {/* <LiveClassSearch/> */}
      <div className={`card card-custom `}>
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Live Class
            </span>
          </h3>

          <div className="card-toolbar">
            <Link
              to="/create-live-class"
              className="btn btn-success font-weight-bolder font-size-sm"
            >
              <span className="svg-icon svg-icon-md svg-icon-white">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Add-user.svg"
                  )}
                  className="h-50 align-self-center"
                ></SVG>
              </span>
              Add Live Class
            </Link>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="pl-0" style={{ width: "30px" }}>
                    Serial
                  </th>
                  <th className="pr-0 " style={{ width: "150px" }}>
                    Title
                  </th>
                  <th style={{ minWidth: "100px" }}>Start</th>
                  <th style={{ minWidth: "1o0px" }}>End</th>

                  <th style={{ minWidth: "80px" }}>Event Code</th>
                  <th style={{ minWidth: "80px" }}>Status</th>
                  <th style={{ minWidth: "80px" }}>Join Now</th>
                  <th
                    className="pr-0 text-center"
                    style={{ minWidth: "100px" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>

              {Datas.map((item, index) => (
                <tbody>
                  <tr>
                    <td className="pl-0">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {i++}
                      </span>
                    </td>

                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.title}
                      </span>
                      <span className="text-muted font-weight-bold text-muted d-block"></span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {moment(`${item.startTime}`).format("Do MMMM YYYY")}
                      </span>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {moment(`${item.startTime}`).format("h:mm a")}
                      </span>
                      <span className="text-muted font-weight-bold text-muted d-block">
                        {moment(`${item.startTime}`).format("ddd")}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {moment(`${item.endTime}`).format("Do MMMM YYYY")}
                      </span>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {moment(`${item.endTime}`).format("h:mm a")}
                      </span>
                      <span className="text-muted font-weight-bold text-muted d-block">
                        {moment(`${item.endTime}`).format("ddd")}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.eventCode}
                      </span>
                    </td>
                    <td>
                      <div class="btn btn-sm font-weight-bold btn-light-primary">
                        Active
                      </div>
                      {/* <div class="btn btn-sm font-weight-bold btn-light-warning">Due</div> */}
                    </td>
                    <td>
                      <Button
                        disabled={item.serverType === "teachStack-studio" || item.serverType === "teachStack-remote" ? false : true}
                        onClick={()=>handleJoinMeeting(item?.meetingId, item?._id)}
                        sx={{ m: 1 }}
                        variant="contained"
                      >
                        Join
                      </Button>
                    </td>
                    <td className="pr-0 text-center">
                      <a
                        href="#"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Settings-1.svg"
                            )}
                          ></SVG>
                        </span>
                      </a>

                      <Link
                        to={{
                          pathname: "/update-live-class",
                          state: item,
                        }}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-warning">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          ></SVG>
                        </span>
                      </Link>

                      <a
                        href="#"
                        onClick={() => setModal(item) & setShowDelete(true)}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                          ></SVG>
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
            <div className="row align-items-center">
              <div className="col-md-8 col-sm-8">
                <div className="d-flex flex-wrp py-2 mr-3">
                  <TablePagination
                    component="div"
                    count={Count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={row}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
              <br></br> <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
          {/* end::Table */}
        </div>
      </div>
    </div>
  );

}

import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import PreviewIcon from "@mui/icons-material/Preview";
import IconButton from "@mui/material/IconButton";
import EmptyComponent from "../../../Components/EmptyComponent";
import moment from "moment";

const columns = [
  { id: "0", label: "Serial", minWidth: 50, align: "center" },
  { id: "1", label: "Course", minWidth: 50, align: "center" },
  { id: "2", label: "Subject", minWidth: 270, align: "center" },
  { id: "3", label: "Rating", minWidth: 100, align: "center" },
  { id: "4", label: "Comments", minWidth: 100, align: "center" },
  { id: "4", label: "Solved Date", minWidth: 100, align: "center" },
  { id: "4", label: "Details", minWidth: 100, align: "center" },
];

export default function SolvedDoubtsTable({
  page,
  rowsPerPage,
  count,
  doubts,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOpenDoubtDialouge,
}) {
  const handleChangePageTable = (event, newPage) => {
    handleChangePage(newPage);
  };

  const handleChangeRowsPerPageTable = (event) => {
    handleChangeRowsPerPage(+event.target.value);
    handleChangePage(0);
  };

  const handleDoubtClick = (id) => {
    handleOpenDoubtDialouge(true, id);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {doubts.length > 0 && (
        <>
          <TableContainer sx={{ maxHeight: 500 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {doubts?.map((item, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell key={index} align={"center"}>
                      {index + 1}
                    </TableCell>

                    <TableCell key={index} align={"center"}>
                      {item?.course?.title}
                    </TableCell>

                    <TableCell key={index} align={"center"}>
                      {item?.subject?.title}
                    </TableCell>

                    <TableCell key={index} align={"center"}>
                      {item?.review?.rating}
                    </TableCell>

                    <TableCell key={index} align={"center"}>
                      {item?.review?.comment}
                    </TableCell>

                    <TableCell key={index} align={"center"}>
                      {item?.timeline?.map((item) => {
                        return item?.event === "Solved"
                          ? moment(item?.timeStamp).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )
                          : null;
                      })}
                    </TableCell>
                    <TableCell key={index} align={"center"}>
                      <IconButton
                        onClick={() => handleDoubtClick(item?._id)}
                        aria-label="delete"
                      >
                        <PreviewIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePageTable}
            onRowsPerPageChange={handleChangeRowsPerPageTable}
          />
        </>
      )}
      {doubts.length == 0 && <EmptyComponent title="No Records Found" />}
    </Paper>
  );
}

import React, {useState} from 'react'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import LiveClassList from "./component/LiveClassList"
import Create from "./Create"

export default function LiveClassDashBoard() {
    const [mode, setMode] = useState("dashboard") //create//

    const dashbaord = (
        <Card sx={{ width: '100%', padding: 2 }}>
            <CardHeader
                title={"Live-class dashbaord"}
                action={
                    <Tooltip title="add new room">
                        <IconButton onClick={()=>setMode("create")}>
                            <AddBoxIcon fontSize='large' />
                        </IconButton>
                    </Tooltip>
                }
            />
            <CardContent>
            <LiveClassList />
            </CardContent>
        </Card>
    )

    const create = (
        <Card sx={{ width: '100%', padding: 2 }}>
            <CardHeader
                title={"add new room"}
                action={
                    <Tooltip title="Back to dashboard">
                        <IconButton onClick={()=>setMode("dashboard")}>
                            <ArrowBackIosIcon sx={{fontWeight : 800}} fontSize='large' />
                        </IconButton>
                    </Tooltip>
                }
            />
            <CardContent>
               <Create />
            </CardContent>
        </Card>
    )

    return (
        <div>
            {mode === "dashboard" && dashbaord}
            {mode === "create" && create}
        </div>
    )
}

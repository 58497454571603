import React from "react";
import "../../Css/root.css";
import { useState, useEffect } from "react";
import axios from "../../axios";
import api from "../../api";
import { Link, Redirect } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {actions} from "../../Components/Toaster/ToastReducer";
import * as auth from "../../../app/modules/Auth/_redux/authRedux";
export default function CreateOrder(props) {

    const dispatch = useDispatch();
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const [tohome, setTohome] = React.useState(false);

    const [phoneNumber, setPhoneNumber] = useState();

    const [lastPaymentDate, setLastPaymentDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [user, setUser] = useState();
    const [message, setMessage] = useState();

    const [selectedSubsCriptionID, setSelectedSubsCriptionID] = useState();
    const [transactionid, setTransactionID] = useState();
    const [walletFromAmount, setWalletFromAmount] = useState();

    const [goals, setGoals] = useState();
    const [subs, setSubs] = useState();

    const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

    useEffect(() => {
        goal();
    }, []);

    async function goal() {
        settoken(localStorage.getItem("user"));
        const response = await axios.get(api.orderGetGoals, data);
        setGoals(response.data);
    }
    async function getSubs(selected) {
        const response = await axios.get(api.orderGetSubscriptions+`?goalId=${selected}`, data);
        setSubs(response.data.subscriptions);
    }
    const data = {
        headers: {
            "x-auth-token": token,
        },
    };
    if (tohome === true) {
        return <Redirect to="/order" />;
    }
    const handleSubmit = () => {
        const body = {
            subscriptionId: selectedSubsCriptionID,
            lastPaymentDate: lastPaymentDate,
            endDate: endDate,
            amountFromWallet: walletFromAmount
        }
        const UserID = user ? user._id : ''
        axios
            .post(`${api.orderCreate}${UserID}`, body, data)
            .then(async (resp) => {
                const { status } = resp;
                if(status == 400)
                {
                    dispatch(actions.Danger(`${resp.data}`));
                }
                if (status == 200) {
                    setTohome(true)
                    setIsPaymentSuccess(true)
                }
            })
            .catch((error) => {
                dispatch(actions.Danger(`${error.response.data.toString()}`));
            });
    }

    const handleSeacrhUser = () => {
        const url = `${api.getuserByphone}/${phoneNumber}`;
        axios
            .get(url, data)
            .then((resp) => {
                const { user, message } = resp.data;
                if(!user)
                {
                    dispatch(actions.Danger("User not Found"));
                }
                setUser(user);
                setMessage(message);
            })
            .catch((error) => {
                dispatch(actions.Danger("Something went wrong"));
                if (error.response.status == 401) {
                    return dispatch(props.logout());
                } else {
                    return error.response.status;
                }
            });
    }
    return (
        <div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-header">
                                    <h3 className="card-title"> Create Order</h3>
                                    <div className="card-toolbar">
                                        <div className="example-tools justify-content-center">
                                            <Link
                                                className="btn btn-success font-weight-bolder font-size-sm"
                                                to="/coupon"
                                            >
                                                <i className="flaticon2-list"></i>
                                                Create Order
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <form className="form">
                                    <input type="hidden" />
                                    <div className="card-body">

                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Phone Number :
                                            </label>
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    placeholder="01xxxxxxxxx"
                                                    onChange={(e) => {
                                                        setPhoneNumber(`88${e.target.value}`)
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-success mr-2"
                                                    onClick={handleSeacrhUser}
                                                >
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            user ?
                                                <div className="form-group row">
                                                    <label className="col-lg-10 col-form-label text-left">
                                                        {message}
                                                    </label>

                                                </div> : null
                                        }
                                        {
                                            user ?

                                                <>
                                                    <div className="form-group row">
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Selected User :
                                                        </label>
                                                        <div className="col-lg-10">
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                value={user ? user.email : ''}
                                                                placeholder="User Email"
                                                                onChange={(e) => {
                                                                    //setName(e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Goal :
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <Form.Control
                                                                as="select"
                                                                onChange={(e) => {
                                                                    const selected = e.target.value;
                                                                    getSubs(selected);
                                                                }}
                                                            >
                                                                <option value="" defaultChecked>
                                                                    Select Goal
                                                                </option>
                                                                {
                                                                    goals ?
                                                                        goals.map(item =>
                                                                            <option value={item._id}>
                                                                                {item.title}
                                                                            </option>
                                                                        ) : null
                                                                }

                                                            </Form.Control>
                                                        </div>
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Subscription :
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <Form.Control
                                                                as="select"
                                                                onChange={(e) => {
                                                                    const selected = e.target.value;
                                                                    setSelectedSubsCriptionID(selected)
                                                                }}
                                                            >
                                                                <option value="" defaultChecked>
                                                                    Select Subscription
                                                                </option>
                                                                {
                                                                    subs ?
                                                                        subs.map(item =>

                                                                            <option value={item._id}>
                                                                                {item.title}
                                                                            </option>
                                                                        ) : null
                                                                }
                                                            </Form.Control>
                                                        </div>
                                                    </div>

                                                    
                                                    <div className="form-group row">
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Amount From Wallet
                                                        </label>
                                                        <div className="col-lg-3">
                                                            <input
                                                                type="text"
                                                                className="form-control "
                                                                placeholder="Enter Amount"
                                                                onChange={(e) => {
                                                                    setWalletFromAmount(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                

                                                    <div className="form-group row">
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            Last Payment Date :
                                                        </label>
                                                        <div className="col-lg-3">
                                                          <DatePicker timeIntervals ={1} showTimeSelect selected={lastPaymentDate} onChange={(date) => setLastPaymentDate(date)} />
                                                        </div>
                                                        <label className="col-lg-2 col-form-label text-left">
                                                            End Date :
                                                        </label>
                                                        <div className="col-lg-3">
                                                          <DatePicker timeIntervals ={1} showTimeSelect selected={endDate} onChange={(date) => setEndDate(date)} />
                                                        </div>
                                                    </div>

                                                </> : null
                                        }


                                        <div className="form-group row"></div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-lg-2"></div>
                                            <div className="col-lg-10">
                                                <button
                                                    type="button"
                                                    className="btn btn-success mr-2"
                                                    onClick={handleSubmit}
                                                >
                                                    Submit
                                                </button>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useEffect, useState } from "react"
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import Grid from '@mui/material/Grid'
import BasicSelector from "../../../Components/BasicSelector"

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import PolicyIcon from '@mui/icons-material/Policy';

export default function ClassFilterByCourse(props) {
    // Data states
  
    const [courses, setCourses] = useState([])
    const [subjects, setSubjects] = useState([])
    const [chapters, setChapters] = useState([])
    const [classes, setClasses] = useState([])
    const [inputCourseTitle, setInputCourseTitle] = useState('')

    // Selection states
    const [selectedCourse, setSelectedCourse] = useState('')
    const [selectedSubject, setSelectedSubject] = useState('')
    const [selectedChapter, setSelectedChapter] = useState('')

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    // Select data props
    const courseData = {
        title: "Courses",
        items: courses,
        selected: selectedCourse
    }

    const subjectData = {
        title: "Subject",
        items: subjects,
        selected: selectedSubject
    }

    const chapterData = {
        title: "Chapter",
        items: chapters,
        selected: selectedChapter
    }

    const classData = {
        title: "Class",
        items: classes,
        selected: props.selectedClass
    }


    // Select elements
    const courseSelector = (
        <Grid item>
            <BasicSelector data={courseData} sx={{ width: 200 }} handleSelection={handleCourseSelection} />
        </Grid>
    )

    const subjectSelector = (
        <Grid item>
            <BasicSelector data={subjectData} sx={{ width: 200 }} handleSelection={handleSubjectSelection} />
        </Grid>
    )

    const chapterSelector = (
        <Grid item>
            <BasicSelector data={chapterData} sx={{ width: 200 }} handleSelection={handleChapterSelection} />
        </Grid>
    )

    const classSelector = (
        <Grid item>
            <BasicSelector data={classData} sx={{ width: 200 }} handleSelection={handleClassSelection} />
        </Grid>
    )

    const courseInput = (
        <Grid item>
            <TextField
                id="input-with-icon-textfield"
                label="Course Title"
                onChange={(e)=>setInputCourseTitle(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PolicyIcon />
                        </InputAdornment>
                    ),
                }}
                variant="standard"
            />
            {inputCourseTitle && <Button onClick={getCoursesForFilter} variant="contained">Search</Button>}
        </Grid>
    )



    // Data fetching and state handling functions
    async function getCoursesForFilter() {
        setSelectedSubject('')
        setSelectedChapter('')
        props.handleClassSelection('')
        axios
            .get(`admin/courses/get-courses-by-title/${inputCourseTitle}`, config)
            .then((response) => {
                setCourses(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    async function getSubjectsForFilter(courseId) {
        axios
            .get(api.getFilterSubjects + courseId, config)
            .then((response) => {
                setSubjects(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    async function getChaptersForFilter(subjectId) {
        axios
            .get(api.getFilterChapters + subjectId, config)
            .then((response) => {
                setChapters(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    async function getClassesForFilter(chapterId) {
        axios
            .get(api.getFilterClasses + chapterId, config)
            .then((response) => {
                setClasses(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    function handleCourseSelection(courseId) {
        setSelectedCourse(courseId)
        props.handleCourseSelection(courseId)
        props.handleClassSelection('')
        getSubjectsForFilter(courseId)
        setSelectedSubject('')
        setSelectedChapter('')
    }

    function handleSubjectSelection(subjectId) {
        setSelectedSubject(subjectId)
        getChaptersForFilter(subjectId)
        props.handleClassSelection('')
        setSelectedChapter('')
    }

    function handleChapterSelection(chapterId) {
        setSelectedChapter(chapterId)
        getClassesForFilter(chapterId)
        props.handleClassSelection('')
    }

    function handleClassSelection(classId) {
       props.handleClassSelection(classId)
    }

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
            {courseInput}
            {courseSelector}
            {subjectSelector}
            {chapterSelector}
            {classSelector}
        </Grid>
    )
}
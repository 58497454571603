import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import '../roots_Comp/Css/root.css'

/*  User page */
import User from "../roots_Comp/Pages/User/User"
import CreateUser from "../roots_Comp/Pages/User/CreateUser"
import UpdateUser from "../roots_Comp/Pages/User/UpdateUser";
import BulkUserRole from "../roots_Comp/Pages/User/BulkRoleUpdate/BulkUserRole";
//import Courses from "../roots_Comp/Pages/Courses"
// import Subject from "../roots_Comp/Pages/Subject"
// import Chapter from "../roots_Comp/Pages/Chapter"

import Subscription from '../roots_Comp/Pages/Subscription'

import Goal from '../roots_Comp/Pages/Goal'
import CreateGoal from "../roots_Comp/SubPages/CreateGoal";
import EditGoals from "../roots_Comp/SubPages/EditGoals";
import CreateSubscription from "../roots_Comp/SubPages/CreateSubscription";
import EditSubscription from "../roots_Comp/SubPages/EditSubscriptions";
// import CreateCourse from "../roots_Comp/SubPages/CreateCourse";
// import UpdateCourse from "../roots_Comp/SubPages/updateCourses";
// import CreateSubject from "../roots_Comp/SubPages/createSubject";
// import UpdateSubject from "../roots_Comp/SubPages/UpdateSubject";
// import CreateChapter from "../roots_Comp/SubPages/CreateChapter";
// import UpdateChapter from "../roots_Comp/SubPages/EditChapters";
// import Classes from "../roots_Comp/Pages/Classes";
// import CreateClass from "../roots_Comp/SubPages/CreateClass";
// import UpdateClass from "../roots_Comp/SubPages/UpdateClass";

/* coupon pages */
import Coupon from "../roots_Comp/Pages/Coupon/Coupon";
import CreateCoupon from "../roots_Comp/Pages/Coupon/CreateCoupon";
import EditCoupon from "../roots_Comp/Pages/Coupon/EditCoupon";
import BulkCoupondelete from "../roots_Comp/Pages/Coupon/BulkCouponDelete/BulkCoupondelete"
import BulkCouponUpdate from "../roots_Comp/Pages/Coupon/BulkCouponUpdate/BulkCouponUpdate";
import SubscriptionAddToCoupon from "../roots_Comp/Pages/Coupon/AddSubscriptionToCoupon/SubscriptionAddToCoupon";
import CourseAddToCoupon from "../roots_Comp/Pages/Coupon/AddCatalogCourseToCoupon/CourseAddToCoupon";
// import McqExam from "../roots_Comp/Pages/McqExam";
// import McqQuestion from "../roots_Comp/Pages/McqQuestion";
// import CreateMcqExam from "../roots_Comp/SubPages/CreateMcqExam";
// import EditMcqExam from "../roots_Comp/SubPages/EditMcqExam";
// import CreateMCQ from "../roots_Comp/SubPages/CreateMcq";
// import UpdateMCQ from "../roots_Comp/SubPages/UpdateMcq";
// import PreviewExam from "../roots_Comp/SubPages/PreviewExam";

import BrandPartner from "../roots_Comp/Pages/BrandPartner/BrandPartner";
import UpdateBrandPartner from "../roots_Comp/SubPages/UpdateBrandpartner";
import CreateBrandPartner from "../roots_Comp/Pages/BrandPartner/Create/CreateBrandPartner";

import Educator from "../roots_Comp/Pages/Educator/Educator";
import UpdateEducator from "../roots_Comp/SubPages/UpdateEducator";

import LiveClass from "../roots_Comp/Pages/LiveClass/LiveClass";
import CreateLiveClass from "../roots_Comp/Pages/LiveClass/CreateLiveClass";
import UpdateLiveClass from "../roots_Comp/Pages/LiveClass/UpdateLiveClass";
import LiveClassDashBoard from "../roots_Comp/Pages/LiveClass/LiveClassDashBoard/LiveClassDashBoard";

import PaymentPlanExplorer from "../roots_Comp/Pages/PaymentPlan/PaymentPlanExplorer";
import Payment from "../roots_Comp/Pages/Payment/Payment";
import CreatePayment from "../roots_Comp/Pages/Payment/CreatePayment";
import UpdatePayment from "../roots_Comp/Pages/Payment/UpdatePayment";

import Order from "../roots_Comp/Pages/Order/Order";
import CreateOrder from "../roots_Comp/Pages/Order/CreateOrder";
import BulkUserOrderCreate from "../roots_Comp/Pages/Order/BulkUserCreateOrder/BulkUserOrderCreate"
import BulkUserSubscriptionOrder from "../roots_Comp/Pages/Order/BulkSubscriptionOrder/BulkUserSubscriptionOrder";

import Sms from "../roots_Comp/Pages/SMS/Sms";
import CreateSms from "../roots_Comp/Pages/SMS/CreateSms";
import SingleUserSms from "../roots_Comp/Pages/SMS/SingleUserSms";
import CustomNumberSms from "../roots_Comp/Pages/SMS/CustomNumberSms";
import SendSmsAllUser from "../roots_Comp/Pages/SMS/SendSmsAllUser";
import SmsRoleWise from "../roots_Comp/Pages/SMS/SmsRoleWise";
import SendDynamicSms from "../roots_Comp/Pages/SMS/DynamicSms/SendDynamicSms"


import CourseExplorer from "../roots_Comp/Pages/CourseExplorer/CourseExplorer";

import AffiliatePayment from "../roots_Comp/Pages/AffiliatePayment/AffiliatePayment";
import CreateAffiliate from "../roots_Comp/Pages/AffiliatePayment/CreateAffiliate";
import EditAffiliatePayment from "../roots_Comp/Pages/AffiliatePayment/EditAffiliatePayment";
import ExamExplorer from "../roots_Comp/Pages/ExamExplorer/ExamExplorer";
import DoubtExplorer from "../roots_Comp/Pages/DoubtExplorer/DoubtExplorer";
import DoubtHistory from "../roots_Comp/Pages/DoubtHistory/DoubtHistory"
/* Wallet amount add to user */
import WalletAdd from "../roots_Comp/Pages/Wallet/WalletAdd"

import Notification from "../roots_Comp/Pages/Notification/Notification"
import UserWrittenExam from "../roots_Comp/Pages/UserWrittenExam/UserWrittenExam";

export default function BasePage() {

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/users" />
        }
        {/* <ContentRoute path="/courses" component={Courses} />
        <ContentRoute path="/Create_courses" component={CreateCourse} />
        <ContentRoute path="/update_courses" component={UpdateCourse} /> */}
        {/* Course Explorer */}
        <ContentRoute path="/course-explorer" component={CourseExplorer} />
        {/* Exam Explorer */}
        <ContentRoute path="/exam-explorer" component={ExamExplorer} />
        
        {/* Doubt Explorer */}
        <ContentRoute path="/doubt-explorer" component={DoubtExplorer} />
        
        {/* Doubt History */}
        <ContentRoute path="/doubt-history" component={DoubtHistory} />

        {/* User Routes */}
        <ContentRoute path="/users" component={User} />
        <ContentRoute path="/create_user" component={CreateUser} />
        <ContentRoute path="/update_user" component={UpdateUser} />
        <ContentRoute path="/bulk-user-role" component={BulkUserRole} />
        {/* User Routes */}
        {/* Goals */}
        <ContentRoute path="/goals" component={Goal} />
        <ContentRoute path="/createGoal" component={CreateGoal} />
        <ContentRoute path="/UpdateGoal" component={EditGoals} />
        {/* Goals */}
        {/* Subscription */}
        <ContentRoute path="/subscriptions" component={Subscription} />
        <ContentRoute
          path="/Create_subscriptions"
          component={CreateSubscription}
        />
        <ContentRoute path="/Edit_subscriptions" component={EditSubscription} />
        {/* Subscription */}
        {/* Subjects */}
        {/* <ContentRoute path="/subjects" component={Subject} />
        <ContentRoute path="/createsubject" component={CreateSubject} />
        <ContentRoute path="/updatesubject" component={UpdateSubject} /> */}
        {/* Subjects */}
        {/* Chapters */}
        {/* <ContentRoute path="/chapters" component={Chapter} />
        <ContentRoute path="/Create_chapters" component={CreateChapter} />
        <ContentRoute path="/Update_chapters" component={UpdateChapter} /> */}
        {/* Chapters */}
        {/* Classes */}
        {/* <ContentRoute path="/classes" component={Classes} />
        <ContentRoute path="/Create_Class" component={CreateClass} />
        <ContentRoute path="/Update_classes" component={UpdateClass} /> */}
        {/* Classes */}
        {/* Live Classes */}
        <ContentRoute path="/live-videos" component={LiveClass} />
        <ContentRoute path="/create-live-class" component={CreateLiveClass} />
        <ContentRoute path="/update-live-class" component={UpdateLiveClass} />
        <ContentRoute
          path="/liveClass-dashboard"
          component={LiveClassDashBoard}
        />
        {/*Live Classes*/}
        {/* Coupon */}
        <ContentRoute path="/coupon" component={Coupon} />
        <ContentRoute path="/createCoupon" component={CreateCoupon} />
        <ContentRoute path="/EditCoupon" component={EditCoupon} />
        <ContentRoute
          path="/subscription-add-bulkCoupon"
          component={SubscriptionAddToCoupon}
        />
        <ContentRoute
          path="/catalogCourse-add-bulkCoupon"
          component={CourseAddToCoupon}
        />
        <ContentRoute path="/bulk-coupon-delete" component={BulkCoupondelete} />
        <ContentRoute path="/bulk-coupon-update" component={BulkCouponUpdate} />
        {/* Coupon */}
        {/* payment */}
        <ContentRoute path="/payment" component={Payment} />
        <ContentRoute path="/add-payment" component={CreatePayment} />
        <ContentRoute path="/edit-payment" component={UpdatePayment} />
        PaymentPlanExplorer
        {/* payment */}
        {/* paymentPlan */}
        <ContentRoute path="/payment-plan" component={PaymentPlanExplorer} />
        {/* payment */}
        {/* order */}
        <ContentRoute path="/order" component={Order} />
        <ContentRoute path="/add-order" component={CreateOrder} />
        <ContentRoute
          path="/bulk-user-orderCreate"
          component={BulkUserOrderCreate}
        />
        <ContentRoute
          path="/bulk-user-subscription-order"
          component={BulkUserSubscriptionOrder}
        />
        {/* order */}
        {/* MCQ-Exam */}
        {/* <ContentRoute path="/mcq-exam" component={McqExam} />
        <ContentRoute path="/create_mcq_exam" component={CreateMcqExam} />
        <ContentRoute path="/Edit-mcq-exam" component={EditMcqExam} />
        <ContentRoute path="/preview-exam" component={PreviewExam} /> */}
        {/* MCQ-Exam */}
        {/* MCQ-Question */}
        {/* <ContentRoute path="/mcq-question" component={McqQuestion} />
        <ContentRoute path="/create-mcq-question" component={CreateMCQ} />
        <ContentRoute path="/update-mcq-question" component={UpdateMCQ} /> */}
        {/* MCQ-Question*/}
        {/* BrandPartner */}
        <ContentRoute path="/brandpartner" component={BrandPartner} />
        <ContentRoute
          path="/create-brandPartner"
          component={CreateBrandPartner}
        />
        <ContentRoute
          path="/Update-brandpartner"
          component={UpdateBrandPartner}
        />
        {/* BrandPartner */}
        {/* Educator */}
        <ContentRoute path="/educator" component={Educator} />
        <ContentRoute path="/Update-Educator" component={UpdateEducator} />
        {/* Educator */}
        {/* SMS */}
        <ContentRoute path="/sms" component={Sms} />
        <ContentRoute path="/create-sms" component={CreateSms} />
        <ContentRoute path="/sms-single-user" component={SingleUserSms} />
        <ContentRoute path="/sms-custom-number" component={CustomNumberSms} />
        <ContentRoute path="/sms-subscribe" component={CreateSms} />
        <ContentRoute path="/sms-send-to-all" component={SendSmsAllUser} />
        <ContentRoute path="/sms-send-role-wise" component={SmsRoleWise} />
        <ContentRoute path="/sms-dynamic-sms" component={SendDynamicSms} />
        {/* SMS */}
        {/* AFFILIATE - PAYMENT */}
        <ContentRoute path="/affiliate-payment" component={AffiliatePayment} />
        <ContentRoute
          path="/create-affiliate-payment"
          component={CreateAffiliate}
        />
        <ContentRoute
          path="/update-affiliate-payment"
          component={EditAffiliatePayment}
        />
        {/* AFFILIATE - PAYMENT */}
        {/* Wallet - amount */}
        <ContentRoute path="/excel-to-walletAmount" component={WalletAdd} />
        {/* Wallet - amount */}

        {/* Notification */}
        <ContentRoute path="/notification" component={Notification} />
        {/* Notification */}

        {/* User Written Exam */}
        <ContentRoute path="/writtenExamResult" component={UserWrittenExam} />
        {/* User Written Exam */}
    
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}

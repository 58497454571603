import React, { useEffect, useRef, useState } from "react"
import axiosStatic from "axios"

/* @MUI COMPONENT */
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import MultiSelectorChip from "../../../Components/MultiSelectorChip"
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import RestoreIcon from '@mui/icons-material/Restore'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import Skeleton from '@mui/material/Skeleton'
import PersonIcon from '@mui/icons-material/Person'
import AddIcon from '@mui/icons-material/Add'

import { useSnackbar } from "notistack"

/* @CUSTOM COMPONENT */
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import BasicSelector from "../../../Components/BasicSelector"
import RichTextEditor from "../../../Components/RichTextEditor"
import BasicCircularProgress from "../../../Components/BasicCircularProgress"

export default function DoubtSolverDialog(props) {
    const [subjectsLoading, setSubjectsLoading] = useState(false)
    const [solversLoading, setSolversLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [doubtSolvers, setDoubtSolvers] = useState([])
    const [subjectWiseSolvers, setSubjectWiseSolvers] = useState([])

    const [selectedSubject, setSelectedSubject] = useState('')
    const [selectedSolvers, setSelectedSolvers] = useState('')

    const courseId = props.courseId
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    const subjectData = {
        title: "Subject",
        items: subjectWiseSolvers.map(function (x) {
            return {
                _id: x._id,
                title: x.title
            }
        }),
        selected: selectedSubject
    }

    const solverData = {
        title: "Solvers",
        index: "name",
        items: doubtSolvers,
        selected: selectedSolvers
    }

    const handleSubjectSelection = (subjectId) => {
        setSelectedSolvers(subjectWiseSolvers.find((x) => x._id === subjectId).doubtSolvers)
        setSelectedSubject(subjectId)
    }

    const handleSolverSelection = (value) => {
        setSelectedSolvers(value)
    }

    const handledeleteSolverFromSubject = async (solvers) => {
        axiosStatic.all(solvers.map(x => axios.delete(
            api.deleteDoubtSolverFromSubject,
            {
                data: {
                    subjectId: selectedSubject,
                    solverId: x,
                    courseId: props.courseId
                }, headers: {
                    "x-auth-token": localStorage.getItem("user"),
                },
            }
        )))
            .then(axiosStatic.spread(function (...response) {
                setSubmitLoading(false)
                enqueueSnackbar("Doubt solver delete from subject successfully", { variant: 'success' })
            }))
            .catch((error) => {
                enqueueSnackbar("Doubt solver delete failed: " + error.response.data, { variant: 'error' })
            })
    }

    const handleAddSolverToSubject = async (newSolverIds) => {
        axiosStatic.all(newSolverIds.map(x => axios.post(
            api.addSolverToSubject,
            {
                courseId: props.courseId,
                subjectId: selectedSubject,
                solverId: x
            },
            config
        )))
            .then(axiosStatic.spread(function (...response) {
                setSubmitLoading(false)
                enqueueSnackbar(response[0]?.data, { variant: 'success' })
            }))
            .catch((error) => {
                enqueueSnackbar("Doubt solver add failed: " + error.response.data, { variant: 'error' })
            })
    }


    const handleSubmit = () => {
        const previousSolvers = subjectWiseSolvers.find((x) => x._id === selectedSubject).doubtSolvers
        const newSolverIds = selectedSolvers
            .filter((x) => !previousSolvers.includes(x))
        const isRemovedSolver = previousSolvers.filter(n => !selectedSolvers.includes(n))

        if (newSolverIds.length) {
            handleAddSolverToSubject(newSolverIds)
        }

        if (isRemovedSolver?.length > 0) {
            handledeleteSolverFromSubject(isRemovedSolver)
        }
        setSubmitLoading(true)
    }

    const handleClose = () => {
        props.handleClose()
    }

    async function fetchDoubtSolverForCourse(courseId) {
        setSubjectsLoading(true)
        axios
            .get(api.getCourseExplorerDoubtSolversByCourse + courseId, config)
            .then((response) => {
                setSubjectsLoading(false)
                setSubjectWiseSolvers(response.data)
            })
            .catch((error) => {
                enqueueSnackbar("Solver data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function fetchDoubtSolvers() {
        setSolversLoading(true)
        axios
            .get(api.getCourseExplorerDoubtSolvers, config)
            .then((response) => {
                setSolversLoading(false)
                setDoubtSolvers(response.data)
            })
            .catch((error) => {
                enqueueSnackbar("Solver data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        if (!props.open || !props.courseId) return
        fetchDoubtSolverForCourse(props.courseId)
        fetchDoubtSolvers(props.courseId)
    }, [props.open])

    const subjectSelector = (
        <BasicSelector
            data={subjectData}
            sx={{ width: 200 }}
            handleSelection={handleSubjectSelection}
        />
    )

    const solverSelector = (
        <MultiSelectorChip
            data={solverData}
            handleSelection={handleSolverSelection}
            sx={{ width: 400 }}
        />
    )

    return (
        <Dialog open={Boolean(props.open)} onClose={handleClose}>
            <DialogTitle>Doubt Solvers</DialogTitle>
            <DialogContent sx={{ width: 600 }}>
                <Stack direction="column" spacing={4}>
                    {subjectSelector}
                    {selectedSubject && solverSelector}
                </Stack>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    color="secondary"
                    onClick={handleSubmit}
                    loading={subjectsLoading || solversLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
                <LoadingButton
                    color="inherit"
                    sx={{ ml: 2 }}
                    onClick={handleClose}
                    loading={subjectsLoading || solversLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<RestoreIcon />}
                    variant="contained"
                >
                    Cancel
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

import React, { useState } from "react"
import "../../Css/root.css"
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import CourseFilter from "../../Components/Filters/CourseFilter"
import ClassFilter from "../../Components/Filters/ClassFilter"
import SubjectFilter from "../../Components/Filters/SubjectFilter"
import FreeCourseFilter from "../../Components/Filters/FreeCourseFilter"
import CatalogCourseFilter from "../../Components/Filters/CatalogCourseFilter"
import CatalogCourseClassFilter from "../../Components/Filters/CatalogCourseClassFilter"
import ExamExplorerModeMenu from "./ExamExplorerModeMenu"
import SearchCourseFilter  from "./SearchQuizOrExam/CourseFilter"
import ClassFilterByCourse from "./SearchQuizOrExam/ClassFilter"

import { useSnackbar } from "notistack"
import SubscriptionExamList from "./ExamList/SubscriptionExamList"
import WrittenExamList from "./WrittenExam/WrittenExamList"
import SubscriptionQuizList from "./ExamList/SubscriptionQuizList"
import QuestionTable from "./Question/QuestionTable"

const ExplorerMode = {
    SubscriptionExam: "SubscriptionExam",
    SubscriptionQuiz: "SubscriptionQuiz",
    WrittenExam: "WrittenExam",
    FreeCourseExam: "FreeCourseExam",
    CatalogCourseExam : "CatalogCourseExam",
    CatalogCourseQuiz : "CatalogCourseQuiz",
    searchExam : "SearchExam",
    searchQuiz : "SearchQuiz"
 }

const ExplorerModeMenu = {
    SubscriptionExam: "Subscription Exam",
    SubscriptionQuiz: "Subscription Quiz",
    WrittenExam: "Written Exam",
    FreeCourseExam: "Free Course Exam",
    CatalogCourseExam : "Catalog Course Exam",
    CatalogCourseQuiz : "Catalog Course Quiz",
    searchExam : "SearchExam",
    searchQuiz : "SearchQuiz"
}

const FilterTitleByMode = {
    SubscriptionExam: "Select Course",
    SubscriptionQuiz: "Select Class",
    FreeCourseExam: "Select Free Course",
    CatalogCourseExam : "Select Catalog Course",
    CatalogCourseQuiz : "Select Class",
    searchExam : "SearchExam",
    searchQuiz : "SearchQuiz",
    WrittenExam: "Select Written Exam",
}

export default function ExamExplorer() {
    const [explorerMode, setExplorerMode] = useState(ExplorerMode.SubscriptionExam)
    const [selectedGoal, setSelectedGoal] = useState('')
    const [selectedSubscription, setSelectedSubscription] = useState('')
    const [selectedCourse, setSelectedCourse] = useState('')
    const [selectedSubject, setSelectedSubject] = useState('')
    const [selectedChapter, setSelectedChapter] = useState('')
    const [selectedClass, setSelectedClass] = useState('')

    const [selectedExam, setSelectedExam] = useState('')
    const [selectedWrittenExam, setSelectedWrittenExam] = useState('')

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    const handleModeChange = (mode) => {
        setSelectedGoal('')
        setSelectedSubscription('')
        setSelectedCourse('')
        setSelectedSubject('')
        setSelectedChapter('')
        setSelectedClass('')

        setExplorerMode(ExplorerMode[mode])
    }

    const courseSelector = (
        <CourseFilter
            selectedCourse={selectedCourse}
            handleCourseSelection={(courseId) => {
                setSelectedCourse(courseId)
            }}
        />
    )

    const searchCourseAndSelect = (
        <SearchCourseFilter
            selectedCourse={selectedCourse}
            handleCourseSelection={(courseId) => {
                setSelectedCourse(courseId)
            }}
        />
    )

    const searchCourseAndClassSelect = (
        <ClassFilterByCourse
            selectedClass={selectedClass}
            handleCourseSelection={(courseId)=>{
                setSelectedCourse(courseId)
            }}
            handleClassSelection={(classId) => {
                setSelectedClass(classId)
            }}
        />
    )

    const freeCourseSelector = (
        <FreeCourseFilter
            selectedCourse={selectedCourse}
            handleCourseSelection={(courseId) => {
                setSelectedCourse(courseId)
            }}
        />
    )

    const catalogCourseSelector = (
        <CatalogCourseFilter
            selectedCourse={selectedCourse}
            handleCourseSelection={(courseId) => {
                setSelectedCourse(courseId)
            }}
        />
    )

    const catalogClassSelector = (
        <CatalogCourseClassFilter
            selectedClass={selectedClass}
            handleCourseSelection={(courseId)=>{
                setSelectedCourse(courseId)
            }}
            handleClassSelection={(classId) => {
                setSelectedClass(classId)
            }}
        />
    )

    const classSelector = (
        <ClassFilter
            selectedClass={selectedClass}
            handleCourseSelection={(courseId)=>{
                setSelectedCourse(courseId)
            }}
            handleClassSelection={(classId) => {
                setSelectedClass(classId)
            }}
        />
    )

    const subjectSelector = (
        <SubjectFilter
            selectedSubject={selectedSubject}
            handleCourseSelection={(courseId)=>{
                setSelectedCourse(courseId)
            }}
            handleSubjectSelection={(subjectId)=>{
                setSelectedSubject(subjectId)
            }}
        />
    )

    const filterBox = (
        <Card>
            <CardHeader
                title={FilterTitleByMode[explorerMode]}
                action={
                    <ExamExplorerModeMenu
                        currentMode={explorerMode}
                        explorerModes={ExplorerModeMenu}
                        handleModeSelection={handleModeChange}
                    />
                }
            />
            <CardContent>
                {explorerMode === "SubscriptionExam" && courseSelector}
                {explorerMode === "SubscriptionQuiz" && classSelector}
                {explorerMode === "WrittenExam" && subjectSelector}
                {explorerMode === "FreeCourseExam" && freeCourseSelector}
                {explorerMode === "CatalogCourseExam" && catalogCourseSelector} 
                {explorerMode === "CatalogCourseQuiz" && catalogClassSelector}
                {explorerMode === "SearchExam" && searchCourseAndSelect}
                {explorerMode === "SearchQuiz" && searchCourseAndClassSelect}
            </CardContent>
        </Card>
    )

    const subscriptionExamList = (
        <Grid item>
            <Box sx={{ pt: 1 }}>
                <SubscriptionExamList
                    courseId={selectedCourse}
                    selectedExam={selectedExam}
                    handleExamSelection={(examId) => setSelectedExam(examId)}
                />
            </Box>
        </Grid>
    )

    const writtenExamList = (
            <Box sx={{ pt: 1, m : 1 }}>
                <WrittenExamList
                    courseId={selectedCourse}
                    subjectId={selectedSubject}
                    selectedExam={selectedWrittenExam}
                    handleExamSelection={(examId) => setSelectedWrittenExam(examId)}
                />
            </Box>
    )
    const subscriptionQuizList = (
        <Grid item>
            <Box sx={{ pt: 1 }}>
                <SubscriptionQuizList
                    courseId={selectedCourse}
                    classId={selectedClass}
                    selectedExam={selectedExam}
                    handleExamSelection={(examId) => setSelectedExam(examId)}
                />
            </Box>
        </Grid>
    )

    const freeExamList = (
        <Grid item>
            <Box sx={{ pt: 1 }}>
                <SubscriptionExamList
                    courseId={selectedCourse}
                    selectedExam={selectedExam}
                    handleExamSelection={(examId) => setSelectedExam(examId)}
                />
            </Box>
        </Grid>
    )

    const CatalogCourseExamList = (
        <Grid item>
            <Box sx={{ pt: 1 }}>
                <SubscriptionExamList
                    courseId={selectedCourse}
                    selectedExam={selectedExam}
                    handleExamSelection={(examId) => setSelectedExam(examId)}
                />
            </Box>
        </Grid>
    )

    const CatalogCourseQuizList = (
        <Grid item>
            <Box sx={{ pt: 1 }}>
                <SubscriptionQuizList
                    courseId={selectedCourse}
                    classId={selectedClass}
                    selectedExam={selectedExam}
                    handleExamSelection={(examId) => setSelectedExam(examId)}
                />
            </Box>
        </Grid>
    )

    const questionTable = (
        <Grid item>
            <Box sx={{ pt: 1 }}>
                <QuestionTable
                    examId={selectedExam}
                />
            </Box>
        </Grid>
    )


    return (
        <React.Fragment>
            {filterBox}

            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                {explorerMode === "SubscriptionExam" && selectedCourse && subscriptionExamList}
                {explorerMode === "SubscriptionExam" && selectedCourse && selectedExam && questionTable}
                
                {/* Written Exam Ui */}
                {explorerMode === "WrittenExam" && selectedCourse && selectedSubject && writtenExamList}
                
                {/* Subscription Quiz Ui */}
                {explorerMode === "SubscriptionQuiz" && selectedClass && subscriptionQuizList}
                {explorerMode === "SubscriptionQuiz" && selectedClass && selectedExam && questionTable}

                {/* Free Course Quiz Ui */}
                {explorerMode === "FreeCourseExam" && selectedCourse && freeExamList}
                {explorerMode === "FreeCourseExam" && selectedCourse && selectedExam && questionTable}

                {/* Catalog course Exam Ui */}
                {explorerMode === "CatalogCourseExam" && selectedCourse && CatalogCourseExamList}
                {explorerMode === "CatalogCourseExam" && selectedCourse && selectedExam && questionTable}

                {/* Catalog course Quiz Ui */}
                {explorerMode === "CatalogCourseQuiz" && selectedClass && CatalogCourseQuizList}
                {explorerMode === "CatalogCourseQuiz" && selectedClass && selectedExam && questionTable}

                {/* Search courses & Exam Ui */}
                {explorerMode === "SearchExam" && selectedCourse && subscriptionExamList}
                {explorerMode === "SearchExam" && selectedCourse && selectedExam && questionTable}

                {/* Search Course & Quiz Ui */}
                {explorerMode === "SearchQuiz" && selectedClass && subscriptionQuizList}
                {explorerMode === "SearchQuiz" && selectedClass && selectedExam && questionTable}

            </Grid>
        </React.Fragment>
    )
}
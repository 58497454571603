import React, { useEffect, useState } from "react"

/* @MUI COMPONENT */
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import RestoreIcon from '@mui/icons-material/Restore'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Stack from '@mui/material/Stack'
import {DateTimePicker} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSnackbar } from "notistack"

/* @CUSTOM COMPONENT */
import "../../Css/root.css"
import axios from "../../axios"
import api from "../../api"
import BasicCircularProgress from "../../Components/BasicCircularProgress"
import { isNumber } from "lodash"

export default function ExamAddUpdateDialog(props) {
    const [examLoading, setExamLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    const [title, setTitle] = useState('')
    const [order, setOrder] = useState('')
    const [isActive, setIsActive] = useState(true)
    const [isFree, setIsFree] = useState(false)
    const [noOfQuestion, setNoOfQuestion] = useState('')
    const [marksPerQuestion, setMarksPerQuestion] = useState('')
    const [negativeMarksPerQuestion, setNegativeMarksPerQuestion] = useState(null)
    const [durationInSec, setDurationInSec] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')

    const [errorMessage, setErrorMessage] = useState({})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    const handleClose = () => {
        props.handleClose()
    }

    const handleSubmit = () => {
        if(!isFormDataValid()) return
        props.examId ? updateExam() : addNewExam()
    }

    const handleReset = () => {
        resetFormData()
    }

    const isFormDataValid = () => {
        var isValid = true
        if(!title.length) {
            isValid = false
            setErrorMessage(message => {
                return {...message, title: 'Title must not be empty.'}
            })
        }
        if(!order) {
            isValid = false
            setErrorMessage(message => {
                return {...message, order: 'Order must be a valid number'}
            })
        }
        if(!noOfQuestion) {
            isValid = false
            setErrorMessage(message => {
                return {...message, noOfQuestion: 'Must be a valid number'}
            })
        }
        if(!marksPerQuestion) {
            isValid = false
            setErrorMessage(message => {
                return {...message, marksPerQuestion: 'Must be a valid number'}
            })
        }
        let negative_marksPerQuestion = Number(negativeMarksPerQuestion)
        if(!isNumber(negative_marksPerQuestion) ||  negative_marksPerQuestion < 0) {
            isValid = false
            setErrorMessage(message => {
                return {...message, negativeMarksPerQuestion: 'Must be a valid number'}
            })
        }
        if(!durationInSec) {
            isValid = false
            setErrorMessage(message => {
                return {...message, durationInSec: 'Must be a valid number'}
            })
        }
        
        return isValid
    }

    function resetFormData() {
        setTitle('')
        setOrder('')
        setIsActive(true)
        setIsFree(false)
        setNoOfQuestion('')
        setMarksPerQuestion('')
        setNegativeMarksPerQuestion(null)
        setDurationInSec('')
    }

    async function fetchExamData(examId) {
        setExamLoading(true)
        axios
            .get(api.getExamExplorerExamById + examId, config)
            .then((response) => {
                setTitle(response.data.title)
                setIsActive(response.data.isActive)
                setIsFree(response.data.isFree)
                setStartTime(response.data.startTime)
                if(response.data.endTime) setEndTime(response.data.endTime)
                if(response.data.order) setOrder(response.data.order)
                if(response.data.noOfQuestion) setNoOfQuestion(response.data.noOfQuestion)
                if(response.data.marksPerQuestion) setMarksPerQuestion(response.data.marksPerQuestion)
                setNegativeMarksPerQuestion(response?.data?.negativeMarksPerQuestion)
                if(response.data.durationInSec) setDurationInSec(response.data.durationInSec)

                setExamLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Exam data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function addNewExam() {
        setSubmitLoading(true)

        let temp_startTime = new Date(startTime)
        temp_startTime.setSeconds(0)
        temp_startTime.setMilliseconds(0)

        let temp_endTimeTime = new Date(endTime)
        temp_endTimeTime.setSeconds(0)
        temp_endTimeTime.setMilliseconds(0)

        const examPayload = {
            title: title,
            order: order,
            examType: props.examType,
            course: props.courseId,
            isFree: isFree,
            isActive: isActive,
            noOfQuestion: noOfQuestion,
            marksPerQuestion: marksPerQuestion,
            durationInSec: durationInSec,
            negativeMarksPerQuestion: Number(negativeMarksPerQuestion),
            startTime: temp_startTime,
            endTime : temp_endTimeTime
        }
        axios
            .post(api.examExplorerNewExam, examPayload, config)
            .then((response) => {
                //enqueueSnackbar(response.data, { variant: 'success' })
                setSubmitLoading(false)
                handleClose()
                if(props?.classId){
                    props.handleSuccessAddQuiztoExam(response.data._id)
                    props.handleSuccess()
                }else{
                    props.handleSuccess()
                }
            })
            .catch((error) => {
                enqueueSnackbar("Add new exam failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function updateExam() {
        setSubmitLoading(true)

        let temp_startTime = new Date(startTime)
        temp_startTime.setSeconds(0)
        temp_startTime.setMilliseconds(0)

        let temp_endTimeTime = new Date(endTime)
        temp_endTimeTime.setSeconds(0)
        temp_endTimeTime.setMilliseconds(0)

        const examPayload = {
            title: title,
            order: order,
            isFree: isFree,
            isActive: isActive,
            noOfQuestion: noOfQuestion,
            marksPerQuestion: marksPerQuestion,
            durationInSec: durationInSec,
            negativeMarksPerQuestion: Number(negativeMarksPerQuestion),
            startTime: temp_startTime,
            endTime : temp_endTimeTime
        }
        axios
            .put(api.examExplorerUpdateExam + props.examId, examPayload, config)
            .then((response) => {
                setSubmitLoading(false)
                enqueueSnackbar(response.data, { variant: 'success' })
                handleClose()
                props.handleSuccess()
            })
            .catch((error) => {
                enqueueSnackbar("Update exam failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        // TODO: Stop initial loading
        if(!props.open) {
            return
        }
        if(props.examId) {
            fetchExamData(props.examId)
        }
    }, [props.open])

    const appBar = (
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Add or Update Exam
                </Typography>
                <LoadingButton
                    color="secondary"
                    onClick={handleSubmit}
                    loading={examLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
                <LoadingButton
                    color="inherit"
                    sx={{ ml: 2 }}
                    onClick={handleReset}
                    loading={examLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<RestoreIcon />}
                    variant="contained"
                >
                    Reset
                </LoadingButton>
            </Toolbar>
        </AppBar>
    )

    const titleField = (
        <TextField
            required
            error={!!errorMessage.title}
            variant="outlined"
            label="Title"
            helperText={errorMessage.title}
            sx={{ minWidth: 300 }}
            value={title}
            onChange={(event) => {
                const text = event.target.value
                if(!text.length) {
                    setErrorMessage(message => {
                        return {...message, title: 'Title must not be empty.'}
                    })
                } else {
                    setErrorMessage(message => {
                        return {...message, title: ''}
                    })
                }
                setTitle(event.target.value)
            }}
        />
    )

    const orderField = (
        <TextField
            required
            error={!!errorMessage.order}
            variant="outlined"
            type="number"
            label="Order"
            helperText={errorMessage.order}
            sx={{ minWidth: 300 }}
            value={order}
            onChange={(event) => {
                const order = event.target.value
                if(!order) {
                    setErrorMessage(message => {
                        return {...message, order: 'Order must be a valid number.'}
                    })
                } else {
                    setErrorMessage(message => {
                        return {...message, order: ''}
                    })
                }
                setOrder(event.target.value)
            }}
        />
    )

    const isActiveSwitch = (
        <FormControlLabel
            label="Is Active"
            control={
                <Switch
                    checked={isActive}
                    onChange={(event) => setIsActive(event.target.checked)}
                />
            }
        />
    )

    const isFreeSwitch = (
        <FormControlLabel
            label="Is Free"
            control={
                <Switch
                    checked={isFree}
                    onChange={(event) => setIsFree(event.target.checked)}
                />
            }
        />
    )

    const noOfQuestionField = (
        <TextField
            required
            error={!!errorMessage.noOfQuestion}
            variant="outlined"
            type="number"
            label="Total Questions"
            helperText={errorMessage.noOfQuestion}
            sx={{ minWidth: 300 }}
            value={noOfQuestion}
            onChange={(event) => {
                const noOfQuestion = event.target.value
                if(!noOfQuestion) {
                    setErrorMessage(message => {
                        return {...message, noOfQuestion: 'Total questions must be a valid number.'}
                    })
                } else {
                    setErrorMessage(message => {
                        return {...message, noOfQuestion: ''}
                    })
                }
                setNoOfQuestion(event.target.value)
            }}
        />
    )

    const marksPerQuestionField = (
        <TextField
            required
            error={!!errorMessage.marksPerQuestion}
            variant="outlined"
            type="number"
            label="Marks per Question"
            helperText={errorMessage.marksPerQuestion}
            sx={{ minWidth: 300 }}
            value={marksPerQuestion}
            onChange={(event) => {
                const marksPerQuestion = event.target.value
                if(!marksPerQuestion) {
                    setErrorMessage(message => {
                        return {...message, marksPerQuestion: 'Must be a valid number.'}
                    })
                } else {
                    setErrorMessage(message => {
                        return {...message, marksPerQuestion: ''}
                    })
                }
                setMarksPerQuestion(event.target.value)
            }}
        />
    )

    const durationInSecField = (
        <TextField
            required
            error={!!errorMessage.durationInSec}
            variant="outlined"
            type="number"
            label="Duration(sec)"
            helperText={errorMessage.durationInSec}
            sx={{ minWidth: 300 }}
            value={durationInSec}
            onChange={(event) => {
                const durationInSec = event.target.value
                if(!durationInSec) {
                    setErrorMessage(message => {
                        return {...message, durationInSec: 'Must be a valid number.'}
                    })
                } else {
                    setErrorMessage(message => {
                        return {...message, durationInSec: ''}
                    })
                }
                setDurationInSec(event.target.value)
            }}
        />
    )

    const negativeMarksPerQuestionField = (
        <TextField
            required
            error={!!errorMessage.negativeMarksPerQuestion}
            variant="outlined"
            type="number"
            label="Negative Mark per Question"
            helperText={errorMessage.negativeMarksPerQuestion}
            sx={{ minWidth: 300 }}
            value={negativeMarksPerQuestion}
            onChange={(event) => {
                const negativeMarksPerQuestion = event.target.value
                if(negativeMarksPerQuestion < 0) {
                    setErrorMessage(message => {
                        return {...message, negativeMarksPerQuestion: 'Must be a valid number.'}
                    })
                } else {
                    setErrorMessage(message => {
                        return {...message, negativeMarksPerQuestion: ''}
                    })
                }
                setNegativeMarksPerQuestion(event.target.value)
            }}
        />
    )

    const startTimePicker = (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                label="Starting Time"
                value={startTime}
                onChange={(newValue) => setStartTime(newValue)}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        required
                        sx={{ minWidth: 300 }}
                    />
                }
            />
        </LocalizationProvider>
    )

    const endTimePicker = (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                label="Ending Time"
                value={endTime}
                onChange={(newValue) => setEndTime(newValue)}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        sx={{ minWidth: 300 }}
                    />
                }
            />
        </LocalizationProvider>
    )

    return (
        <Dialog fullScreen disableEnforceFocus open={Boolean(props.open)} onClose={handleClose}>
            {appBar}
            {(examLoading || submitLoading) ? <BasicCircularProgress /> :
                <Box sx={{ m: 2 }}>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={8}>
                        <Stack direction="column" spacing={4}>
                            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                                {titleField}
                                {orderField}
                            </Stack>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                {isActiveSwitch}
                                {isFreeSwitch}
                            </Stack>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                {noOfQuestionField}
                                {marksPerQuestionField}
                            </Stack>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                {negativeMarksPerQuestionField}
                                {durationInSecField}
                            </Stack>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                {startTimePicker}
                                {endTimePicker}
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>}
        </Dialog>
    )
}

import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";

import axios from "../../axios"
import api from "../../api"
import DeleteModal from "../../Components/DeleteModal";

import { useSnackbar } from "notistack"
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import { Link } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import SubjectSelector from './SubjectSelector'
import DoubtSolveDialog from './DoubtSolveDialog'
import DoubtFilter from './DoubtFilter'
import DoubtExportSubjectWise from "./DoubtExportSubjectWise/DoubtFilterSubjectWise"
import DoubtFilterCourseWise from "./DoubtFilterCourseWise"

const TAG = "DoubtExplorer"
const generateKey = (pre) => {
    return `${TAG}_${pre}_${new Date().getTime()}`
}

export default function DoubtExplorer(props) {

    const [token, settoken] = useState(localStorage.getItem("user"));
    const [Datas, setData] = useState([]);
    const [Count, setCount] = useState();
    const [row, setRow] = useState(10);
    const [page, setpage] = useState(0);
    const dispatch = useDispatch();
    const [url, seturl] = useState(api.getDoubts);
    const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);

    const[selectedCourseId, setSelectedCourseId]= useState()
    const[selectedSubjectId, setSelectedSubjectId]= useState()

    const [selectedSubjectInCourse, setSelectedSubjectInCourse] = useState('')
    const [selectedDoubt, setSelectedDoubt] = useState('')
    const [doubts, setDoubts] = useState([])
    const [openDoubtDialog, setOpenDoubtDialog] = useState(false)
    const [refreshKey, setRefreshKey] = useState(0)
    const [showDelete, setShowDelete] = useState(false);
	const handleCloseDelete = () => setShowDelete(false);

    const [selectDoubt, setSelectDoubt]= useState({show: false, doubtId : ''});
    const [deleteDoubt, setDeleteDoubt] = useState({})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    var i = (page * row) + 1;
    let data = {
        headers: {
            "x-auth-token": token,
        },
    };

    const handleSubjectSelection = (value) => {
        setSelectedSubjectInCourse(value)
    }

    const handleMoreAction = (doubtId) => {
        setSelectedDoubt(doubtId)
        setOpenDoubtDialog(true)
    }

    const refresh = () => {
        setRefreshKey(prevKey => prevKey + 1)
    }

    const handleChangePage = (event, newPage) => {
        setpage(newPage);
        my_async_fn(row, newPage, url, selectedCourseId, selectedSubjectId);
    };

    const handleChangeRowsPerPage = (event) => {
        setRow(event.target.value);
        setpage(0);
        forceUpdate();
        my_async_fn(event.target.value, 0, url, selectedCourseId, selectedSubjectId);
    };

    useEffect(() => {
        if (!selectedSubjectInCourse) return null
      //  getDoubtsBySubjectInCourse(selectedSubjectInCourse.subject._id, selectedSubjectInCourse.course._id)
    }, [selectedSubjectInCourse, refreshKey])


    useEffect(() => {
        my_async_fn(row, page , url, selectedCourseId, selectedSubjectId)
    }, [])

    // async function getDoubtsBySubjectInCourse(subjectId, courseId) {
    //     axios
    //         .get(api.getDoubtsBySubjectInCourse + "subjectId=" + subjectId + "&courseId=" + courseId, config)
    //         .then((response) => {
    //             setDoubts(response.data)
    //         })
    //         .catch((error) => {
    //            // enqueueSnackbar("Doubt data fetch failed: " + error.response.data, { variant: 'error' })
    //         })
    // }

    async function my_async_fn(row, page, url, courseId, subjectId) {
        let api_url = url + "pageNo=" + page + "&row=" + row
        if(courseId){
            api_url += `&courseId=${courseId}`
        }
        if(subjectId){
            api_url += `&subjectId=${subjectId}`
        }
        axios
            .get(api_url, data)
            .then((resp) => {
                setData(resp.data.result);
                setCount(resp.data.count);
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    return dispatch(props.logout());
                } else {
                    return error.response.status;
                }
            });
    }

    const handleCourseSelection = (courseId) => {
        setSelectedCourseId(courseId)
        setSelectedSubjectId(null)
        my_async_fn(row, page, url, courseId, null)
    }

    const handleSubjectIdSelection = (subjectId) => {
        my_async_fn(row, page, url, selectedCourseId, subjectId)
        setSelectedSubjectId(subjectId)
    }

    return (
        <React.Fragment>
            {selectDoubt.show && <DoubtSolveDialog open={selectDoubt.show} doubtId={selectDoubt.doubtId} handleCloseDialouge={()=> setSelectDoubt({show:false, doubtId: ''})} />}
            
			<DeleteModal
				detail={deleteDoubt}
				url={api.deleteDoubt}
				showDelete={showDelete}
				handleCloseDelete={handleCloseDelete}
				reset={() => {
					my_async_fn(row, page, url, selectedCourseId, selectedSubjectId);
				}}
			/>
            <Box sx={{ p: 2, mb: 4 }} component={Paper} elevation={4}>
                <DoubtFilter />
            </Box>

            <Box sx={{ p: 2, mb: 4 }} component={Paper} elevation={4}>
                <DoubtExportSubjectWise />
            </Box>

            <Box sx={{ p: 2, mb: 4 }} component={Paper} elevation={4}>
                <DoubtFilterCourseWise
                    handleSubjectIdSelection={(subjectId) => handleSubjectIdSelection(subjectId)}
                    handleCourseSelection={(courseId) => handleCourseSelection(courseId)}
                />
            </Box>
        
            <Box sx={{ pt: 2 }}>
                <TableContainer component={Paper}>
                    {/* begin::Body */}
                    <div className="card-body py-0">
                        {/* begin::Table */}
                        <div className="table-responsive">
                            <table
                                className="table table-head-custom table-vertical-center"
                                id="kt_advance_table_widget_1"
                            >
                                <thead>
                                    <tr className="text-left">
                                        <th className="pl-0" style={{ width: "30px" }}>
                                            Serial
                                        </th>
                                        <th className="pr-0 " style={{ width: "150px" }}>
                                           Student Name
                                        </th>
                                        <th style={{ minWidth: "100px" }}>Status</th>
                                        <th style={{ minWidth: "1o0px" }}>Course</th>
                                        <th style={{ minWidth: "80px" }}>Subject</th>
                                        <th style={{ minWidth: "80px" }}>Solver</th>
                                    </tr>
                                </thead>
                                {
                                    Datas.map((item, index) =>
                                        <tbody key={index}>
                                            <tr>
                                                <td className="pl-0">
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {i++}
                                                    </span>
                                                </td>

                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {item?.user?.name}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {item?.status}
                                                    </span>

                                                </td>
                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {item?.course?.title}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                    {item?.subject?.title}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                    {item?.solver ? item?.solver?.name : null}
                                                    </span>
                                                </td>
                                                <td>
                                                    {/* <div class="btn btn-sm font-weight-bold btn-light-warning">Due</div> */}
                                                </td>
                                                <td className="pr-0 text-center">
                                                    <a
                                                        onClick={() => setSelectDoubt({show: true, doubtId:item._id})}
                                                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                                    >
                                                        <span className="svg-icon svg-icon-md svg-icon-primary">
                                                            <SVG
                                                                src={toAbsoluteUrl(
                                                                    "/media/svg/icons/General/Settings-1.svg"
                                                                )}
                                                            ></SVG>
                                                        </span>
                                                    </a>

                                                    <a
                                                        // onClick={(e) => { e.preventDefault(); setDeleteDoubt(item); setShowDelete(true)}}
                                                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                                    >
                                                        <span className="svg-icon svg-icon-md svg-icon-danger">
                                                            <SVG
                                                                src={toAbsoluteUrl(
                                                                    "/media/svg/icons/General/Trash.svg"
                                                                )}
                                                            ></SVG>
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    )
                                }
                            </table>
                            <div className="row align-items-center">
                                <div className="col-md-8 col-sm-8">
                                    <div className="d-flex flex-wrp py-2 mr-3">
                                        <TablePagination
                                            component="div"
                                            count={Count}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={row}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </div>
                                </div>
                                <br></br> <br></br>
                                <br></br>
                                <br></br>
                            </div>

                        </div>
                        {/* end::Table */}
                    </div>
                </TableContainer>
            </Box>
            <DoubtSolveDialog
                key={generateKey("doubt-dialog")}
                doubtId={selectedDoubt}
                open={openDoubtDialog}
                handleClose={() => setOpenDoubtDialog(false)}
                handleSuccess={refresh}
            />
        </React.Fragment>
    )
}

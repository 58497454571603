/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import "../../Css/root.css";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import Pagination from '@mui/material/Pagination';
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import api from "../../api";
import TablePagination from "@mui/material/TablePagination";
import DeleteModal from "../../Components/DeleteModal";
import { useDispatch, useReducer } from "react-redux";
import AffiliatePaymentDue from "./AffiliatePaymentDue";
import { Form } from "react-bootstrap";
import moment from 'moment';

export default function AffiliatePayment(props) {

  const handleClose = () => setShow(false);
   const [show, setShow] = React.useState(false);

   const AffiliatePaymentDue = React.lazy(()=> import("./AffiliatePaymentDue"));

  const [token, settoken] = useState(localStorage.getItem("user"));
  const [Datas, setData] = useState([]);
  const [Count, setCount] = useState();
  const [row, setRow] = useState(10);
  const [page, setpage] = useState(0);
  const dispatch = useDispatch();
  const [modal, setModal] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  const [sub, setsub] = useState([]);
  const [cors, setcors] = useState([]);
  const [searchSub, setsearchSub] = useState("");
  const [Datass, setDatass] = useState([]);
  const [url, seturl] = useState(api.affiliateList);
  const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const [subj, setsubj] = useState([]);
  var i = (page * row) + 1;
  let data = {
    headers: {
      "x-auth-token": token,
    },
  };
  async function my_async_fn(row, page, url) {
    axios
      .get(url + "?pageNo=" + page + "&row=" + row, data)
      .then((resp) => {
        setData(resp.data.result.affiliatePayment);
        setCount(resp.data.count);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          return dispatch(props.logout());
        } else {
          return error.response.status;
        }
      });
  }
  const handleChangePage = (event, newPage) => {
    setpage(newPage);
    my_async_fn(row, page, url);
  };
  const handleChangeRowsPerPage = (event) => {
    setRow(event.target.value);
    setpage(0);
    forceUpdate();
    my_async_fn(event.target.value, 0, url);
  };
  async function goals() {
    settoken(localStorage.getItem("user"));
    const response = await axios.get(api.getGoalChap, data);
    setDatass(response.data);
  }

  useEffect(() => {
    my_async_fn(row, page, url);
  }, []);

  function handleSearch() {
    seturl(api.searchChapter + "title=" + searchSub + "&");
    let url = api.searchChapter + "title=" + searchSub + "&";
    my_async_fn(row, page, url);
  }

  return (
    <div>

<div>
        <div className="card card-custom">
          <div className="card-header border-0 py-5 cent">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Affiliate Due  Search
              </span>
            </h3>
          </div>
          <div></div>
          <div className="card-body pt-0 pb-3 ">
            <div className="form-group row">
              <div className="col-lg-12">
                <form>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div
                      className="form-group row"
                      style={{ marginBottom: "0px !important" }}
                    >
                      <div className="col-md-2"></div>
            
                      <div className="col-md-2"></div>

                      <div className="col-md-12 my-4 text-center"></div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-12">
                <form className="custom-form">
                  <div className="input-group col-lg-6 col-md-6 col-sm-6 cent">
                    <AffiliatePaymentDue />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        {/* Affiliate Due search Ends */}
      </div>

      <div>
        <div className="card card-custom">
          <div className="card-header border-0 py-5 cent">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Affiliate Payment Search
              </span>
            </h3>
          </div>
          <div></div>
          <div className="card-body pt-0 pb-3 ">
            <div className="form-group row">
              <div className="col-lg-12">
                <form>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div
                      className="form-group row"
                      style={{ marginBottom: "0px !important" }}
                    >
                      <div className="col-md-2"></div>
            
                      <div className="col-md-2"></div>

                      <div className="col-md-12 my-4 text-center"></div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-12">
                <form className="custom-form">
                  <div className="input-group col-lg-6 col-md-6 col-sm-6 cent">
                    <input
                      type="text"
                      id="search"
                      value={searchSub}
                      className="form-control"
                      placeholder="Search By title"
                      onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                      onChange={(e) => {
                        setsearchSub(e.target.value);
                      }}
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="btn btn-sm btn-success"
                        type="button"
                        onClick={handleSearch}
                      >
                        Search!
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        {/* Search Ends */}
      </div>

      <DeleteModal
        detail={modal}
        url={api.affiliatePaymentDelete}
        showDelete={showDelete}
        handleCloseDelete={handleCloseDelete}
        reset={() => {
          my_async_fn(row, page, url);
        }}
      />

      {/* <LiveClassSearch/> */}
      <div className={`card card-custom `}>
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
               Affiliate Payment
            </span>
          </h3>

          <div className="card-toolbar">
            <Link
              to="/create-affiliate-payment"
              className="btn btn-success font-weight-bolder font-size-sm"
            >
              <span className="svg-icon svg-icon-md svg-icon-white">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Add-user.svg"
                  )}
                  className="h-50 align-self-center"
                ></SVG>
              </span>
               Add new affiliate payment
            </Link>

          </div>

        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="pl-0" style={{ width: "30px" }}>
                    Serial
                  </th>
                  <th className="pr-0 " style={{ width: "150px" }}>
                    Name
                  </th>
                  <th style={{ minWidth: "100px" }}>Phone</th>
                  <th style={{ minWidth: "100px" }}>Email</th>
                  <th style={{ minWidth: "80px" }}>Disburse Amount</th>
                  <th style={{ minWidth: "80px" }}>Disburse Date</th>
                </tr>
              </thead>
              {
               Datas.map((item, index) =>
               <tbody>
                  <tr>
                    <td className="pl-0">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {index+1}
                      </span>
                    </td>

                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.user.name}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      {item.user.phone}
                      </span>

                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.user.email}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                         {item.disbursedAmount}
                      </span>
                    </td>
                    <td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {moment(`${item.disburseDate}`).format("ddd, h:mm:ss a")}
                      </span>
                      <span className="text-muted font-weight-bold text-muted d-block">
                        {moment(`${item.disburseDate}`).format('MM/DD/YYYY')}
                      </span>
                    </td>
                    
                      {/* <div class="btn btn-sm font-weight-bold btn-light-warning">Due</div> */}
                    </td>
                    <td className="pr-0 text-center">
                     
                      <Link
                        to={{
                          pathname: "/update-affiliate-payment",
                          state: item,
                        }}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-warning">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          ></SVG>
                        </span>
                      </Link>
                      <a
                        href="#"
                        onClick={() => setModal(item) & setShowDelete(true)}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                          ></SVG>
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>

               )
              }
            </table>
            <div className="row align-items-center">
              <div className="col-md-8 col-sm-8">
                <div className="d-flex flex-wrp py-2 mr-3">
                  <TablePagination
                    component="div"
                    count={Count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={row}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
              <br></br> <br></br>
              <br></br>
              <br></br>
            </div>

          </div>
          {/* end::Table */}
        </div>
      </div>
    </div>
  );

}

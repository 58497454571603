import React, { useEffect, useState } from "react"
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import AddIcon from '@mui/icons-material/Add'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import ArticleIcon from '@mui/icons-material/Article'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconLabelListSkeleton from "../../../Components/IconLabelListSkeleton"
import ClassAddUpdateDialog from "./ClassAddUpdateDialog"
import SimpleDeleteModal from "../../../Components/SimpleDeleteModal"
import { useSnackbar } from "notistack"
import ClassListMenu from "./ClassListMenu"
import QuizAddDialog from "./QuizAddDialog"

const TAG = "ClassList"
const generateKey = (pre) => {
    return `${TAG}_${ pre }_${ new Date().getTime() }`
}

export default function ClassList(props) {
    const [classes, setClasses] = useState([])
    const [classLoading, setClassLoading] = useState(false)

    const [dialogClassId, setDialogClassId] = useState('')
    const [openClassDialog, setOpenClassDialog] = useState(false)

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deleteModalClassId, setDeleteModalClassId] = useState('')

    const [openQuizDialog, setOpenQuizDialog] = useState(false)
    const [quizDialogClassId, setQuizDialogClassId] = useState('')

    const [refreshKey, setRefreshKey] = useState(0)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user")
        }
    }

    const refresh = () => {
        setRefreshKey(prevKey => prevKey + 1)
    }

    const handleClassSelection = (event) => {
        props.handleClassSelection(event.currentTarget.getAttribute('value'))
    }

    const handleAddOrEditPressed = (classId) => {
        setDialogClassId(classId)
        setOpenClassDialog(true)
    }

    const handleDeletePressed = (classId) => {
        setDeleteModalClassId(classId)
        setOpenDeleteModal(true)
    }

    const handleAddQuizToClass = (classId) => {
        setQuizDialogClassId(classId)
        setOpenQuizDialog(true)
    }
    

    async function getClassesByChapter(chapterId) {
        setClassLoading(true)
        axios
            .get(api.getFilterClasses + chapterId, config)
            .then((response) => {
                setClasses(response.data)
                setClassLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Class data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function deleteClass(classId) {
        axios
            .delete(api.deleteClass + classId, config)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
                refresh()
            })
            .catch((error) => {
                enqueueSnackbar("Delete class failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        getClassesByChapter(props.chapterId)
    },[props.chapterId, refreshKey])

    const classItems = (item) => {
        return (
            <ListItem
                key={item._id}
                secondaryAction={
                    <ClassListMenu
                        value={item._id}
                        editAction={handleAddOrEditPressed}
                        deleteAction={handleDeletePressed}
                        quizAddAction={handleAddQuizToClass}
                    />
                }
            >
                <ListItemButton
                    selected={props.selectedClass === item._id}
                    value={item._id}
                    onClick={handleClassSelection}
                >
                    <ListItemIcon>
                        <ArticleIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={item.title}
                    />
                </ListItemButton>
            </ListItem>
        )
    }

    const classItemsSkeleton = [1, 2, 3].map((value) => <IconLabelListSkeleton key={value} />)

    return (
        <Card sx={{ width: "20vw" }}>
            <CardHeader
                title="Classes"
                action={
                    <Tooltip title="Add New Class">
                        <IconButton aria-label="add" onClick={(_event) => handleAddOrEditPressed('')}>
                            <AddIcon color="primary" sx={{ width: 30, height: 30 }} />
                        </IconButton>
                    </Tooltip>
                }
            />

            <CardContent>
                <List>
                    {classLoading ? classItemsSkeleton : classes.map(classItems)}
                </List>
            </CardContent>
            <ClassAddUpdateDialog
                key={generateKey("class-dialog")}
                chapterId={props.chapterId}
                classId={dialogClassId}
                open={openClassDialog}
                handleClose={() => setOpenClassDialog(false)}
                handleSuccess={refresh}
            />
            <QuizAddDialog
                key={generateKey("quiz-add-dialog")}
                subjectId={props.subjectId}
                classId={quizDialogClassId}
                open={openQuizDialog}
                handleClose={() => setOpenQuizDialog(false)}
            />
            <SimpleDeleteModal
                title="Delete Class"
                open={openDeleteModal}
                handleClose={() => {
                    setDeleteModalClassId('')
                    setOpenDeleteModal(false)
                }}
                handleDelete={() => deleteClass(deleteModalClassId)}
            />
        </Card>
    )
}
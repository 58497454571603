import React from "react";
import "../../Css/root.css";
import { useState, useEffect, useReducer } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link, Redirect } from "react-router-dom";
import axios from "../../axios";
import api from "../../api";
import { Form } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { actions } from "../../Components/Toaster/ToastReducer";
import Roomlist from "../../Components/Roomlist"
export default function CreateLiveClass() {

    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(new Date());
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const [tohome, setTohome] = React.useState(false);
    const [title, setTitle] = useState();
    const [selectedCourseID, setSelectedCourseID] = useState();
    const [selectedSubjectID, setSelectedSubjectID] = useState();
    const [selectedTeacherID, setSelectedTeacherId] = useState();
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [serverType, setServerType] = useState();
    const [meetingId, setMeetingId] = useState("");
    const [meetingPass, setMeetingPass] = useState();
    const [eventCode, setEventCode] = useState();

    const [goals, setGoals] = useState();
    const [courses, setCourses] = useState();
    const [subscription, setSubsCription] = useState();
    const [subjects, setSubject] = useState();
    const [teachers, setTeachers] = useState();


    const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

    let data = {
        headers: {
            "x-auth-token": token,
        },
    };

    useEffect(() => {
        goalsFetch();
    }, []);


    const handleChange = (newValue) => {
        setValue(newValue);
    };

    async function goalsFetch() {

        try {

            settoken(localStorage.getItem("user"));
            const response = await axios.get(api.liveClassGetGoal, data);
            setGoals(response.data);

        } catch (err) {
        }
    }
    async function getSubs(selected) {
        const response = await axios.get(api.liveClassGetSubs + `?goalId=${selected}`, data);
        setSubsCription(response.data.subscriptions);
    }
    async function getsubject(selected) {
        const response = await axios.get(api.liveClassGetSubject + `?courseId=${selected}`, data);
        setSubject(response.data.subjects)
        getteacher(selected)
    }
    async function getteacher(selected) {
        const response = await axios.get(api.liveClassGetTeacher + `?courseId=${selected}`, data);
        setTeachers(response.data.teachers)
    }
    async function getcourses(selected) {
        const response = await axios.get(api.liveClassGetCourse + `?subscriptionId=${selected}`, data);
        setCourses(response.data.courses)
    }

    const handleCreateLiveClass = async () => {
        const body = {
            title: title,
            course: selectedCourseID,
            subject: selectedSubjectID,
            teacher: selectedTeacherID,
            startTime: startTime,
            endTime: endTime,
            serverType: serverType,
            meetingId: meetingId ? meetingId : "",
            meetingPassword: meetingPass ? meetingPass : "",
            eventCode: eventCode ? eventCode : ""
        }
        axios
            .post(api.liveClassCreate, body, data)
            .then(async (resp) => {
                const { status } = resp;
                if (status == 200) {
                    dispatch(actions.Success("Live Class created Succssfully"));
                    setTohome(true);
                }
            })
            .catch((error) => {
                dispatch(actions.Danger("Something Went wrong"));
            });
    }
    if (tohome === true) {
        return <Redirect to="/live-videos" />;
    }
    return (
        <div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-header">
                                    <h3 className="card-title"> Create LiveClass</h3>
                                    <div className="card-toolbar">
                                        <div className="example-tools justify-content-center">
                                            <Link
                                                className="btn btn-success font-weight-bolder font-size-sm"
                                                to="/live-videos"
                                            >
                                                <i className="flaticon2-list"></i>
                                                Create Live Class
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <form className="form">
                                    <input type="hidden" />
                                    <div className="card-body">



                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Enter Title :
                                            </label>
                                            <div className="col-lg-3">
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    placeholder="Title"
                                                    onChange={(e) => {
                                                        setTitle(e.target.value)
                                                        //setnsubj(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Goals
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => {
                                                        getSubs(e.target.value);
                                                    }}
                                                >
                                                    <option value="" defaultChecked>
                                                        Select goals
                                                    </option>
                                                    {goals ?
                                                        goals.map(object =>
                                                            <option value={object._id}>
                                                                {object.title}
                                                            </option>
                                                        ) : null
                                                    }

                                                </Form.Control>
                                            </div>

                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Subscription
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => {
                                                        getcourses(e.target.value);
                                                    }}
                                                >
                                                    <option value="" defaultChecked>
                                                        Select Subscription
                                                    </option>
                                                    {subscription ?
                                                        subscription.map(object =>
                                                            <option value={object._id}>
                                                                {object.title}
                                                            </option>
                                                        ) : null
                                                    }
                                                </Form.Control>
                                            </div>
                                        </div>



                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Course
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => {
                                                        getsubject(e.target.value)
                                                        setSelectedCourseID(e.target.value)
                                                        getteacher(e.target.value)

                                                    }}
                                                >
                                                    <option value="" defaultChecked>
                                                        Select Course
                                                    </option>
                                                    {courses ?
                                                        courses.map(object =>
                                                            <option value={object._id}>
                                                                {object.title}
                                                            </option>
                                                        ) : null
                                                    }

                                                </Form.Control>
                                            </div>

                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Subject
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => {
                                                        setSelectedSubjectID(e.target.value)
                                                    }}
                                                >
                                                    <option value="" defaultChecked>
                                                        Select Subject
                                                    </option>
                                                    {subjects ?
                                                        subjects.map(object =>
                                                            <option value={object._id}>
                                                                {object.title}
                                                            </option>
                                                        ) : null
                                                    }
                                                </Form.Control>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Teacher
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => {
                                                        setSelectedTeacherId(e.target.value);
                                                    }}
                                                >
                                                    <option value="" defaultChecked>
                                                        Select Teacher
                                                    </option>

                                                    {teachers ?
                                                        teachers.map(object =>
                                                            <option value={object._id}>
                                                                {object.name}
                                                            </option>
                                                        ) : null
                                                    }

                                                </Form.Control>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Start Time
                                            </label>
                                            <div className="col-lg-3">
                                                <DatePicker timeIntervals={1} showTimeSelect selected={startTime} onChange={(date) => setStartTime(date)} />
                                            </div>
                                            <label className="col-lg-2 col-form-label text-left">
                                                End Time
                                            </label>
                                            <div className="col-lg-3">
                                                <DatePicker timeIntervals={1} showTimeSelect selected={endTime} onChange={(date) => setEndTime(date)} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-lg-2 col-form-label text-left">
                                                Select Server
                                            </label>
                                            <div className="col-lg-3">
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => {
                                                        setServerType(e.target.value)
                                                    }}
                                                >
                                                    <option value="" defaultChecked>
                                                        Server Type
                                                    </option>
                                                    <option value="zoom">
                                                        Zoom
                                                    </option>
                                                    <option value="teachStack-studio">
                                                        Teach Stack Studio
                                                    </option>
                                                    <option value="teachStack-remote">
                                                        Teach Stack Remote
                                                    </option>
                                                </Form.Control>
                                            </div>
                                        </div>
                                        {
                                            serverType == 'zoom' ?
                                                <div className="form-group row">
                                                    <label className="col-lg-2 col-form-label text-left">
                                                        Meeting ID
                                                    </label>
                                                    <div className="col-lg-3">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Meeting Id"
                                                            onChange={(e) => {
                                                                setMeetingId(e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                    <label className="col-lg-2 col-form-label text-left">
                                                        Meeting Pass
                                                    </label>
                                                    <div className="col-lg-3">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="Meeting Pass"
                                                            onChange={(e) => {
                                                                setMeetingPass(e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div> : null
                                        }
                                        {
                                            serverType == 'teachStack-remote' || serverType == 'teachStack-studio'  ?
                                                <div className="form-group row">
                                                    <div className="col-lg-5">
                                                        <Roomlist serverType={serverType} selectedRoom={meetingId} handleSelectRoom={(state)=>setMeetingId(state)} />
                                                    </div>
                                                </div> : null
                                        }
                                        <div className="form-group row"></div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-lg-2"></div>
                                            <div className="col-lg-10">
                                                <button
                                                    type="button"
                                                    className="btn btn-success mr-2"
                                                    onClick={handleCreateLiveClass}
                                                >
                                                    Submit
                                                </button>
                                                <button type="reset" className="btn btn-secondary">
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

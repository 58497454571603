import React from 'react';
import Button from '@mui/material/Button';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from "../../axios";
import moment from 'moment';

export default function AffiliateDueExportExcel({ selectedCode, payment, fileName, startTime, endTime }) {

    const [token, settoken] = React.useState(localStorage.getItem("user"));

    const fetchData = async(selectedCode)=>{
        try{
            let body={
                coupon : selectedCode,
            }
            if(startTime && endTime){
              body.startTime = moment(startTime).startOf('day').utc()
              body.endTime = moment(endTime).endOf('day').utc()
            }
            const response = await axios.post('/admin/affiliate-payments/get-user-by-coupon', body, {headers: {
                "x-auth-token": token,
              }})
          
            setloading(false);
            return response.data.result;
        }catch(error){

        }
    }
    const [loading, setloading] = React.useState(false);
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = async(selectedCode, payment, fileName) => {
        setloading(true)
        const apiData = []
        var couponInterval = 0;
        while(couponInterval <= selectedCode.length){
            const apiFetchData = await fetchData(selectedCode.slice(couponInterval, couponInterval+100));
            couponInterval = couponInterval + 100;
            apiData.push(...apiFetchData)
        }
        let excelData = []
        if(startTime && endTime){
            for(var index in apiData){
                excelData.push({
                    Coupon : apiData[index].code ,
                    name : apiData[index].User.name,
                    email: apiData[index].User.email,
                    phone : apiData[index].User.phone,
                    role : apiData[index].User.role,
                    StoreAmount : apiData[index].total_StoreAmount,
                    TotalEarnings : apiData[index].total_Earnings,
                    StartDate:startTime, 
                    EndDate:endTime
                });
            }
        }else{
            for(var index in apiData){
                excelData.push({
                    Coupon : apiData[index].code ,
                    name : apiData[index].User.name,
                    email: apiData[index].User.email,
                    phone : apiData[index].User.phone,
                    role : apiData[index].User.role,
                    StoreAmount : apiData[index].total_StoreAmount,
                    TotalEarnings : apiData[index].total_Earnings,
                });
            }
        }
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return <Button onClick={() => exportToCSV(selectedCode, payment, fileName, startTime, endTime)} color="secondary" variant="contained"> {loading ?<>loading</>:<>Export to excel</>}</Button>
}

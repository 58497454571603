import React, { useEffect, useState } from "react"
import "../../Css/root.css"
import axios from "../../axios"
import api from "../../api"
import Grid from '@mui/material/Grid'
import BasicSelector from "../BasicSelector"

export default function CatalogCourseFilter(props) {
    // Data states
    const [goals, setGoals] = useState([])
    const [courses, setCourses] = useState([])

    // Selection states
    const [selectedGoal, setSelectedGoal] = useState('')

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    // Select data props
    const goalData = {
        title: "Goals",
        items: goals,
        selected: selectedGoal
    }

    const courseData = {
        title: "Courses",
        items: courses,
        selected: props.selectedCourse
    }

    // Select elements
    const goalSelector = (
        <Grid item>
            <BasicSelector data={goalData} sx={{ width: 200 }} handleSelection={handleGoalSelection} />
        </Grid>
    )


    const courseSelector = (
        <Grid item>
            <BasicSelector data={courseData} sx={{ width: 200 }} handleSelection={handleCourseSelection} />
        </Grid>
    )

    useEffect(() => {
        getGoalsForFilter();
    }, []);

    // Data fetching and state handling functions
    async function getGoalsForFilter() {
        axios
            .get(api.getFilterGoals, config)
            .then((response) => {
                setGoals(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }


    async function getCoursesForFilter(goalId) {
        axios
            .get(api.getCourseExplorerCatalogCourses + goalId, config)
            .then((response) => {
                setCourses(response.data)
            })
            .catch((error) => {
                return error.response.status
            })
    }

    function handleGoalSelection(goalId) {
        setSelectedGoal(goalId)
        props.handleCourseSelection('')
        getCoursesForFilter(goalId)
    }

    function handleCourseSelection(courseId) {
        props.handleCourseSelection(courseId)
    }

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
            {goalSelector}
            {courseSelector}
        </Grid>
    )
}
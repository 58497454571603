import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useSnackbar } from "notistack"

export default function AlertForm({ handleChangeTitle, handleChangeDescription, handleSubmit }) {
  const [title, setTitle] = useState(""); 
  const [description, setDescription] = useState("");
  const { enqueueSnackbar } = useSnackbar()

  const handleChangeTitlelocal = (event) => {
    setTitle(event.target.value);
    handleChangeTitle(event.target.value);
  };

  const handleChangeMessagelocal = (event) => {
    setDescription(event.target.value);
    handleChangeDescription(event.target.value);
  };

  const handleSubmitAlert = () =>{
    if(!title || !description) return enqueueSnackbar("title and description is not allowed to be empty", { variant: 'warning' })
    handleSubmit(true)
  }

  const handleReset = () => {
    setTitle("");
    setDescription("");
    handleChangeDescription("");
    handleChangeTitle("");
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "70ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="outlined-multiline-flexible"
          label="Alert Title"
          multiline
          maxRows={4}
          value={title}
          onChange={handleChangeTitlelocal}
        />
      </div>
      <div>
        <TextField
          id="outlined-multiline-static"
          label="Alert Description"
          multiline
          value={description}
          onChange={handleChangeMessagelocal}
          rows={4}
          defaultValue=""
        />
      </div>
      <Stack sx={{ m: 1 }} spacing={2} direction="row">
        <Button onClick={handleSubmitAlert} sx={{ width: "100%" }} variant="contained">
          Submit
        </Button>
        <Button onClick={handleReset} sx={{ width: "100%" }} variant="outlined">
          Clear
        </Button>
      </Stack>
    </Box>
  );
}

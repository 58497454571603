import React, { useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import axios from "../../axios";
import api from "../../api"
import Button from '@mui/material/Button';
import {DesktopDatePicker} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';
import moment from 'moment';

import AffiliateDueExportExcel from './AffiliateDueExportExcel';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AffiliatePaymentDue() {

    const [loading, setLoading] = React.useState(true);
    const [token, settoken] = React.useState(localStorage.getItem("user"));
    const [Coupons, setCoupons] = React.useState([])
    const [filteredCoupons, setFilteredCoupons] = React.useState([]);
    const [seacrhCode, setSearchCode] = React.useState('');
    const [selectedCode, setSelectedCode] = React.useState([]);
    const [isFiltered, setIsFiltered] = React.useState(false);
    const [startTime, setStartTime] = React.useState();
    const [endTime, setEndTime] = React.useState();
    const [dueAmount, setDueAmount] = React.useState(null);
    const [showCoupon, setShowCoupon] = React.useState([]);

    useEffect(() => {
        const fetchAllCoupon = async () => {
            try {
                const response = await axios.get(api.getAllCoupon, {
                    method: 'GET',
                    headers: {
                        "x-auth-token": token,
                    }
                });
                var codeList = response.data.result.map(function (item) {
                    return item['code'];
                });
                codeList[0] = 'ALL';
                setCoupons(codeList);
                setLoading(false);
            } catch (error) {
                console.warn(error);
            }
        }
        fetchAllCoupon();
    }, [])

    const SeacrhCoupon = async (value) => {
        try {
            const response = await axios.get(api.searchCoupon + value + `&pageNo=0&row=${200}`, {
                method: 'GET',
                headers: {
                    "x-auth-token": token,
                }
            });
            const codeList = response.data.result.map(function (item) {
                return item['code'];
            })
            setFilteredCoupons(codeList)
            setIsFiltered(true)
        } catch (error) {
            console.warn(error);
        }
    }

    const handleinputCode = (e) => {
        setSearchCode(e.target.value);
        if (e.target.value) {
            if (e.target.value.length % 2 == 0) {
                SeacrhCoupon(e.target.value)
            }
        }
        if (!e.target.value) {
            setFilteredCoupons([]);
            setIsFiltered(false)
        }
    }
    const handleSelectCode = (event, value) => {
        if (value.length > 0) {
            if (value.includes('ALL') && value.length == 1) {
                setShowCoupon(['ALL'])
                setSelectedCode(Coupons)
            } else if (value.includes('ALL') && value.length > 1) {
                if (value[value.length - 1] == 'ALL') {
                    setSelectedCode(Coupons);
                    setShowCoupon(['ALL'])
                } else {
                    const removeItem = value.filter(item => item != 'ALL')
                    const codeList = selectedCode.filter(item => item != removeItem[0]);
                    setSelectedCode(codeList);
                }
            } else {
                setShowCoupon(value)
                setSelectedCode(value)
            }
        } else {
            setShowCoupon(value)
            setSelectedCode([])
            setSearchCode("")
            setIsFiltered(false)
        }
    }
    let data = {
        headers: {
            "x-auth-token": token,
        },
    }
    const fetchDueData = async () => {
        let body ={
            coupon: [...selectedCode]
        }
        if(startTime && endTime){
            body.startTime = moment(startTime).startOf('day').utc()
            body.endTime = moment(endTime).endOf('day').utc()
          }
        const response = await axios.post(api.affiliateSearchDue, body, {
            headers: {
                "x-auth-token": token,
                'Content-Type': 'application/json',
            }
        });
        if (response.data[0]) {
            setDueAmount(response.data[0].totalEarnings)
        } else {
            setDueAmount("")
        }
    }

    const handleGetDueData = () => {
        fetchDueData();
    }

    const handleResetData = () => {
        //reset all items
        setSelectedCode([])
        setShowCoupon([])
        setStartTime(null)
        setEndTime(null)
        setDueAmount("")
    }

    return (
        <>
            {
                loading ? <p>loading</p>
                    :
                    <Box sx={{ width: '100%' }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <Item>
                                    <Autocomplete
                                        value={showCoupon}
                                        onChange={handleSelectCode}
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={isFiltered ? filteredCoupons : Coupons.slice(0, 200)}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option}
                                        renderOption={(props, option, { selected }) => {
                                            return (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selectedCode.includes(option) ? true : false}
                                                    />
                                                    {option ? option : null}
                                                </li>
                                            )
                                        }}
                                        style={{ width: '100%' }}
                                        renderInput={(params) => (
                                            <TextField {...params} value={seacrhCode} onChange={handleinputCode} label="Select Coupons" placeholder="Favorites" />
                                        )}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={6}>
                                <Item>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Stack spacing={3}>
                                            <DesktopDatePicker
                                                label="START DATE"
                                                inputFormat="MM/dd/yyyy"
                                                value={startTime ? startTime : ""}
                                                onChange={(newValue) => setStartTime(newValue)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />

                                        </Stack>
                                    </LocalizationProvider>
                                </Item>
                            </Grid>
                            <Grid item xs={6}>
                                <Item>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Stack spacing={3}>
                                            <DesktopDatePicker
                                                label="End Date"
                                                inputFormat="MM/dd/yyyy"
                                                value={endTime ? endTime : ""}
                                                onChange={(newValue) => setEndTime(newValue)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                    
                                </Item>
                            </Grid>

                            <Grid item xs={6}>
                                <Item>
                                    <Button onClick={handleGetDueData} variant="contained">Search</Button>
                                </Item>
                            </Grid>
                            <Grid item xs={6}>
                                <Item>
                                    <Button onClick={handleResetData} color="secondary" variant="contained">Reset</Button>
                                </Item>
                            </Grid>

                            <Grid item xs={12}>
                                <Item>
                                    <h2> Total Due : {Number(dueAmount).toFixed(2)} </h2>
                                </Item>
                            </Grid>

                            <Grid item xs={12}>
                                <Item>
                                    <AffiliateDueExportExcel
                                        selectedCode={selectedCode}
                                        payment={dueAmount}
                                        fileName="Affiliate payment"
                                        startTime={startTime}
                                        endTime={endTime}
                                    />
                                </Item>
                            </Grid>

                        </Grid>
                    </Box>
            }
        </>
    );
}

import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Editor } from "@tinymce/tinymce-react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from "notistack"

export default function NoticeForm({
  handleChangeTitle,
  handleChangeShortDescription,
  handleChangeDescription,
  handleChangeIsActive,
  handleChangeExpiryDate,
  handleSubmit
}) {
  const [title, setTitle] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [description, setDescription] = useState("");
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [isActive, setIsActive] = useState(true);
  const descRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar()

  const handleChangeTitlelocal = (event) => {
    setTitle(event.target.value);
    handleChangeTitle(event.target.value);
  };

  const handleChangeshortDesclocal = (event) => {
    setShortDescription(event.target.value);
    handleChangeShortDescription(event.target.value);
  };

  const handleChangeIsActivelocal = (event)=>{
    setIsActive(event.target.checked)
    handleChangeIsActive(event.target.checked)
  }

  const handleChangeExpiryDatelocal = (newValue)=>{
    setExpiryDate(newValue)
    handleChangeExpiryDate(newValue)
  }

  const handleReset = () => {
    setTitle("");
    setShortDescription("");
    descRef.current = "";
    setExpiryDate('')
    handleChangeShortDescription("");
    handleChangeTitle("");
    handleChangeExpiryDate("")
  };

  const handleSubmitnotice = () =>{
    if(!title || !shortDescription || !expiryDate) return enqueueSnackbar("Invalid Input", { variant: 'warning' })
    handleChangeDescription(descRef?.current?.getContent())
    handleSubmit(true)
  }

  const filePickerCallBack = (editor, cb) => {
    var input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.onchange = function() {
      var file = this.files[0];

      var reader = new FileReader();
      reader.onload = function() {
        var id = "blobid" + new Date().getTime();
        var blobCache = editor.editorUpload.blobCache;
        var base64 = reader.result.split(",")[1];
        var blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);

        /* call the callback and populate the Title field with the file name */
        cb(blobInfo.blobUri(), { title: file.name });
      };
      reader.readAsDataURL(file);
    };

    input.click();
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "70ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="outlined-multiline-flexible"
          label="Notice Title"
          multiline
          maxRows={4}
          value={title}
          onChange={handleChangeTitlelocal}
        />
      </div>
      <div>
        <TextField
          id="outlined-multiline-static"
          label="Notice Short Description"
          multiline
          value={shortDescription}
          onChange={handleChangeshortDesclocal}
          rows={4}
          defaultValue=""
        />
      </div>
      <div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Expiry Date"
            inputFormat="MM/dd/yyyy"
            value={expiryDate}
            onChange={(newValue) => handleChangeExpiryDatelocal(newValue)}
            renderInput={(params) => <TextField  sx={{maxWidth: 300}} {...params} />}
          />
        </LocalizationProvider>
        <FormControlLabel onChange={(event)=>handleChangeIsActivelocal(event)} sx={{m: 1}} control={<Checkbox checked={isActive ? true : false } />} label="Active" />
      </div>
      <div>
        <Editor
          tinymceScriptSrc="https://cdn.rootsedulive.com/scripts/js/tinymce/tinymce.min.js"
          onInit={(evt, editor) => (descRef.current = editor)}
          initialValue={description ? description : ""}
          init={{
            height: 350,
            width: 580,
            menubar: false,
            automatic_uploads: false,
            file_picker_types: "image",
            file_picker_callback: (cb, _value, _meta) =>
              filePickerCallBack(descRef.current, cb),
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            external_plugins: {
              tiny_mce_wiris:
                "https://cdn.jsdelivr.net/npm/@wiris/mathtype-tinymce4@7.24.0/plugin.min.js",
            },
            toolbar:
              "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
      </div>
      <Stack sx={{ m: 1 }} spacing={2} direction="row">
        <Button onClick={handleSubmitnotice} sx={{ width: "100%" }} variant="contained">
          Submit
        </Button>
        <Button onClick={handleReset} sx={{ width: "100%" }} variant="outlined">
          Clear
        </Button>
      </Stack>
    </Box>
  );
}

import React from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import LoadingButton from '@mui/lab/LoadingButton';
import axios from "../../../axios";
import moment from "moment"

export default function CouponExportToExcel() {
    const [loading, setLoading] = React.useState(false);
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "All Coupons"

    const [token, setToken] = React.useState(localStorage.getItem("user"));

    const fetchData = async () => {
        setLoading(true);
        const searchUrl = `/admin/coupons/get-all-coupon`
        try {
            const response = await axios.get(searchUrl, {
                headers: {
                    "x-auth-token": token,
                }
            })
            return response.data.result;
        } catch (error) {
        }
    }

    const exportToCSV = async () => {
        var importData = await fetchData();
        var excelData = [];
        for (var index in importData) {
            excelData.push({
                Code : importData[index]?.code,
                flatDiscount: importData[index]?.flatDiscount,
                percentDiscount: importData[index]?.percentDiscount,
                isActive: importData[index]?.isActive,
                ExpiredDate: moment(importData[index]?.expiredDate).format('DD-MM-YYYY')
            });
        }
        setLoading(false);
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return <LoadingButton
        color="secondary"
        onClick={() => exportToCSV()}
        loading={loading}
        loadingPosition="start"
        variant="contained"
    >
        EXPORT TO EXCEL
    </LoadingButton>
}

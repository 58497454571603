/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import {checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}

        {/*end::1 Level*/}

        {/*begin::1 Level*/}
 
        {/*end::1 Level*/}

        {/* Components */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">UserData</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/users", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/users">
            <span className="menu-icon far fa-user">
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/goals", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/goals">
            <span className="menu-icon far fa-user">
            </span>
            <span className="menu-text">Goals</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/subscriptions", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/subscriptions">
            <span className="menu-icon flaticon2-calendar-8">
     
            </span>
            <span className="menu-text">Subscriptions</span>
          </NavLink>
        </li> 
        {/* <li
          className={`menu-item ${getMenuItemActive("/courses", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/courses">
            <span className="menu-icon flaticon2-open-text-book">
      
            </span>
            <span className="menu-text">Course</span>
          </NavLink>
        </li> */}
        <li
          className={`menu-item ${getMenuItemActive("/course-explorer", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/course-explorer">
            <span className="menu-icon flaticon2-open-text-book">
      
            </span>
            <span className="menu-text">Course Explorer</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/exam-explorer", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/exam-explorer">
            <span className="menu-icon flaticon2-open-text-book">
      
            </span>
            <span className="menu-text">Exam Explorer</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/doubt-explorer", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/doubt-explorer">
            <span className="menu-icon flaticon2-open-text-book">
      
            </span>
            <span className="menu-text">Doubt Explorer</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/doubt-history", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/doubt-history">
            <span className="menu-icon flaticon2-open-text-book">
      
            </span>
            <span className="menu-text">Doubt History</span>
          </NavLink>
        </li>
        {/* <li
          className={`menu-item ${getMenuItemActive("/subjects", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/subjects">
            <span className="menu-icon flaticon2-open-text-book">
      
            </span>
            <span className="menu-text">Subjects</span>
          </NavLink>
        </li> */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/chapters", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/chapters">
            <span className="menu-icon flaticon2-open-text-book">
      
            </span>
            <span className="menu-text">Chapters</span>
          </NavLink>
        </li> */}

        {/* <li
          className={`menu-item ${getMenuItemActive("/classes", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/classes">
            <span className="menu-icon flaticon2-calendar-8">
     
            </span>
            <span className="menu-text">Recorded Classes</span>
          </NavLink>
        </li>  */}

         <li
          className={`menu-item ${getMenuItemActive("/liveClass-dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/liveClass-dashboard">
            <span className="menu-icon flaticon2-calendar-8">
            </span>
            <span className="menu-text">Live-class Dashboard</span>
          </NavLink>
        </li> 

        <li
          className={`menu-item ${getMenuItemActive("/live-videos", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/live-videos">
            <span className="menu-icon flaticon2-calendar-8">
            </span>
            <span className="menu-text">Live Classes</span>
          </NavLink>
        </li> 


        <li
          className={`menu-item ${getMenuItemActive("/coupon", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/coupon">
            <span className="menu-icon flaticon2-calendar-8">
     
            </span>
            <span className="menu-text">Coupon</span>
          </NavLink>
        </li> 

        <li
          className={`menu-item ${getMenuItemActive("/payment", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/payment">
            <span className="menu-icon flaticon2-calendar-8">
     
            </span>
            <span className="menu-text">Payment</span>
          </NavLink>
        </li> 


        <li
          className={`menu-item ${getMenuItemActive("/payment-plan", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/payment-plan">
            <span className="menu-icon flaticon2-calendar-8">

            </span>
            <span className="menu-text">Payment Plan</span>
          </NavLink>
        </li> 


         <li
          className={`menu-item ${getMenuItemActive("/order", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/order">
            <span className="menu-icon flaticon2-calendar-8">
     
            </span>
            <span className="menu-text">Order</span>
          </NavLink>
        </li> 


{/* 
        <li
          className={`menu-item ${getMenuItemActive("/mcq-exam", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/mcq-exam">
            <span className="menu-icon flaticon2-calendar-8">
     
            </span>
            <span className="menu-text">MCQ Exam</span>
          </NavLink>
        </li>  */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/mcq-question", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/mcq-question">
            <span className="menu-icon flaticon2-contract">
     
            </span>
            <span className="menu-text">MCQ Question</span>
          </NavLink>
        </li>  */}
        <li
          className={`menu-item ${getMenuItemActive("/brandpartner", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/brandpartner">
            <span className="menu-icon flaticon2-gift">
     
            </span>
            <span className="menu-text">Brand Partner</span>
          </NavLink>
        </li> 

        <li
          className={`menu-item ${getMenuItemActive("/educator", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/educator">
            <span className="menu-icon flaticon2-calendar-8">
     
            </span>
            <span className="menu-text">Educator</span>
          </NavLink>
        </li> 

        <li
          className={`menu-item ${getMenuItemActive("/sms", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/sms">
            <span className="menu-icon flaticon2-envelope">
     
            </span>
            <span className="menu-text">SMS</span>
          </NavLink>
        </li> 

        <li
          className={`menu-item ${getMenuItemActive("/sms", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">All User SMS</span>
                </span>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/sms", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sms-subscribe">
                  <span className="menu-bullet menu-bullet-dot">

                  </span>
                  <span className="menu-text">Subscribe Student SMS</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/sms", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sms-single-user">
                  <span className="menu-bullet menu-bullet-dot">
                  </span>
                  <span className="menu-text">Single User SMS</span>
                </NavLink>
              </li>  <li
                className={`menu-item ${getMenuItemActive("/sms", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sms-custom-number">
                  <span className="menu-bullet menu-bullet-dot">

                  </span>
                  <span className="menu-text">Custom Number SMS</span>
                </NavLink>
              </li> 

              <li
                className={`menu-item ${getMenuItemActive("/sms", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sms-send-to-all">
                  <span className="menu-bullet menu-bullet-dot">

                  </span>
                  <span className="menu-text">  SEND SMS TO ALL USER </span>
                </NavLink>
              </li>  


              <li
                className={`menu-item ${getMenuItemActive("/sms", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sms-send-role-wise">
                  <span className="menu-bullet menu-bullet-dot">

                  </span>
                  <span className="menu-text">  SEND SMS TO ROLE-WISE </span>
                </NavLink>
              </li>  


              <li
                className={`menu-item ${getMenuItemActive("/sms", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sms-dynamic-sms">
                  <span className="menu-bullet menu-bullet-dot">
                  </span>
                  <span className="menu-text">Send dynamic sms</span>
                </NavLink>
              </li>  

            </ul>
          </div>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/affiliate-payment", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/affiliate-payment">
            <span className="menu-icon flaticon2-calendar-8">
     
            </span>
            <span className="menu-text">Affiliate payment</span>
          </NavLink>
        </li> 

        <li
          className={`menu-item ${getMenuItemActive("/excel-to-walletAmount", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/excel-to-walletAmount">
            <span className="menu-icon flaticon2-calendar-8">
            </span>
            <span className="menu-text">Wallet</span>
          </NavLink>
        </li> 

        <li
          className={`menu-item ${getMenuItemActive("/notification", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/notification">
            <span className="menu-icon flaticon2-calendar-8">
            </span>
            <span className="menu-text">Notification</span>
          </NavLink>
        </li> 

        <li
          className={`menu-item ${getMenuItemActive("/writtenExamResult", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/writtenExamResult">
            <span className="menu-icon flaticon2-calendar-8">
            </span>
            <span className="menu-text">Written Exam Result</span>
          </NavLink>
        </li> 

        {/* <li
          className={`menu-item ${getMenuItemActive("/orders", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/orders">
            <span className="menu-icon flaticon2-list-2">
             
            </span>
            <span className="menu-text">Orders</span>
          </NavLink>
        </li>
            <li
          className={`menu-item ${getMenuItemActive("/coupons", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/coupons">
            <span className="menu-icon flaticon2-list-2">
              
            </span>
            <span className="menu-text">Coupons</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/appliedcoupons", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/appliedcoupons">
            <span className="menu-icon flaticon2-list-2">
      
            </span>
            <span className="menu-text">Applied Coupons</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/couponpayments", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/couponpayments">
            <span className="menu-icon flaticon2-list-2">
            </span>
            <span className="menu-text">Coupon Payments</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/notifications", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/notifications">
            <span className="menu-icon flaticon2-bell-4">
             
            </span>
            <span className="menu-text">Notifications</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/sms", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/sms">
            <span className="menu-icon flaticon2-chat-1">
           
            </span>
            <span className="menu-text">Sms</span>
          </NavLink>
        </li> */}
        {/* end:: section */}

        {/* Material-UI */}
        {/* <li className="menu-section ">
          <h4 className="menu-text">Courses</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/courses", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/courses">
            <span className="menu-icon flaticon2-open-text-book">
      
            </span>
            <span className="menu-text">Courses</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="menu-icon fas fa-video">
         
            </span>
            <span className="menu-text">Recorded Classes</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Recorded Classes</span>
                </span>
              </li>
              <li
          className={`menu-item ${getMenuItemActive("/subjects", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/subjects">
            <span className="menu-bullet menu-bullet-dot">
           
            </span>
            <span className="menu-text">Subjects</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/chapters", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/chapters">
            <span className="menu-bullet menu-bullet-dot">
              
            </span>
            <span className="menu-text">Chapters</span>
          </NavLink>
        </li>  <li
          className={`menu-item ${getMenuItemActive("/videos", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/videos">
            <span className="menu-bullet menu-bullet-dot">
           
            </span>
            <span className="menu-text">Videos</span>
          </NavLink>
        </li>  <li
          className={`menu-item ${getMenuItemActive("/pdf", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/pdf">
            <span className="menu-bullet menu-bullet-dot">
         
            </span>
            <span className="menu-text">PDF</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/comments", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/comments">
            <span className="menu-bullet menu-bullet-dot">
           
            </span>
            <span className="menu-text">Comments</span>
          </NavLink>
        </li>
            </ul>
          </div>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/liveclass", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/liveclass">
            <span className="menu-icon fas fa-video">
            
            </span>
            <span className="menu-text">Live Class</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/zoom_live_class", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/zoom_live_class">
            <span className="menu-icon fas fa-video">
          
            </span>
            <span className="menu-text">Zoom Live Class</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="menu-icon flaticon2-writing">
            
            </span>
            <span className="menu-text">Exams</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Exams</span>
                </span>
              </li>
              <li
          className={`menu-item ${getMenuItemActive("/course_exam", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/course_exam">
            <span className="menu-bullet menu-bullet-dot">
       
            </span>
            <span className="menu-text">Course Exams</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/questions", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/questions">
            <span className="menu-bullet menu-bullet-dot">
          
            </span>
            <span className="menu-text">Questions</span>
          </NavLink>
        </li>  <li
          className={`menu-item ${getMenuItemActive("/marks", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/marks">
            <span className="menu-bullet menu-bullet-dot">
              
            </span>
            <span className="menu-text">Marks</span>
          </NavLink>
        </li> 
            </ul>
          </div>
        </li>
        <li className="menu-section ">
          <h4 className="menu-text">Subscriptions</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/subscriptions", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/subscriptions">
            <span className="menu-icon flaticon2-calendar-8">
     
            </span>
            <span className="menu-text">Subscriptions</span>
          </NavLink>
        </li>  */}
         {/* <li
          className={`menu-item ${getMenuItemActive("/subscription_plan", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/subscription_plan">
            <span className="menu-icon flaticon2-gift-1">
        
            </span>
            <span className="menu-text">Subscription Plans</span>
          </NavLink>
        </li>  */}

        {/* <li className="menu-section ">
          <h4 className="menu-text">Teachers</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/teachers", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/teachers">
            <span className="menu-icon far fa-user">
              
            </span>
            <span className="menu-text">Teachers</span>
          </NavLink>
        </li>  <li
          className={`menu-item ${getMenuItemActive("/teacher_subscription", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/teacher_subscription">
            <span className="menu-icon flaticon2-list-2">
          
            </span>
            <span className="menu-text">Teacher Subscriptions</span>
          </NavLink>
        </li> 
        <li className="menu-section ">
          <h4 className="menu-text">Admin Controls</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/admin_admin", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin_admin">
            <span className="menu-icon flaticon2-user-1">
      
            </span>
            <span className="menu-text">Admin</span>
          </NavLink>
        </li> 
        <li
          className={`menu-item ${getMenuItemActive("/roles", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/roles">
            <span className="menu-icon flaticon2-protected">
  
            </span>
            <span className="menu-text">Roles</span>
          </NavLink>
        </li>  */}
        <li
          className={`menu-item ${getMenuItemActive("/logout", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/logout">
            <span className="menu-icon fas fa-sign-out-alt">
            
            </span>
            <span className="menu-text">Logout</span>
          </NavLink>
        </li> 
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

import React, { useEffect, useRef, useState } from "react"
import { Editor } from "@tinymce/tinymce-react"
/* @MUI COMPONENT */
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import RestoreIcon from '@mui/icons-material/Restore'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider';
import { useSnackbar } from "notistack"

/* @CUSTOM COMPONENT */
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import BasicCircularProgress from "../../../Components/BasicCircularProgress"
import QuestionSideMenu from "./QuestionSideMenu"

export default function QuestionAddUpdateDialog(props) {

    const [selectedOption, setSelectedOption] = useState("question")
    const [serialNo, setSerialNo] = useState(1)
    const [correctAnswer, setCorrectAnswer] = useState('')

    const [questionLoading, setQuestionLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [formData, setFormData] = useState({ question: '', optionA: '', optionB: '', optionC: '', optionD: '', optionE: '', solution: '' });

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const question = useRef(null);
    const optionA = useRef(null);
    const optionB = useRef(null);
    const optionC = useRef(null);
    const optionD = useRef(null);
    const optionE = useRef(null);
    const solution = useRef(null);

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }


    const handleSubmit = () => {
        // if (!isFormDataValid()) return
        props.questionId ? updateQuestion() : addNewQuestion()

    }

    const handleReset = () => {
        resetFormData()
    }

    function resetFormData() {
    }

    const handleChangeCorrectAnswer = (event) => {
        setCorrectAnswer(event.target.value)
    }

    const filePickerCallBack = (editor, cb) => {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {

                var id = 'blobid' + (new Date()).getTime();
                var blobCache = editor.editorUpload.blobCache;
                var base64 = reader.result.split(',')[1];
                var blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                /* call the callback and populate the Title field with the file name */
                cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
        };

        input.click();
    }


    async function fetchQuestionData(questionId) {
        setQuestionLoading(true)
        axios
            .get(`/admin/mcq-questions/get-question?questionId=${questionId}`, config)
            .then((response) => {
                const { question, optionA, optionB, optionC, optionD, optionE, serialNo, correctAns, solution } = response?.data?.result
                setFormData({ question, optionA, optionB, optionC, optionD, optionE, correctAns, solution })
                setSerialNo(serialNo)
                setCorrectAnswer(correctAns)
                setQuestionLoading(false)
                setSelectedOption("question")
            })
            .catch((error) => {
                enqueueSnackbar("Question data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }


    async function addMCQ(questionID) {
        axios
            .post(api.addMcq + "?examId=" + props.examId + "&questionId=" + questionID, {}, config)
            .then((response) => {
                const { status } = response
                if (status == 200) {
                    enqueueSnackbar("Question added to mcq exam successfully", { variant: 'success' })
                }
            })
            .catch((error) => {
                enqueueSnackbar("Add question in mcq-exam failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function addNewQuestion() {

        if (!question.current.getContent()) return enqueueSnackbar("Question is not allowed to be empty", { variant: 'error' })
        if (!optionA.current.getContent()) return enqueueSnackbar("Option A is not allowed to be empty", { variant: 'error' })
        if (!optionB.current.getContent()) return enqueueSnackbar("Option B is not allowed to be empty", { variant: 'error' })
        if (!optionC.current.getContent()) return enqueueSnackbar("Option C is not allowed to be empty", { variant: 'error' })
        if (!optionD.current.getContent()) return enqueueSnackbar("Option D is not allowed to be empty", { variant: 'error' })
        if (!correctAnswer) return enqueueSnackbar("Correct Answer is not allowed to be empty", { variant: 'error' })
        if (!serialNo) return enqueueSnackbar("Serial is not allowed to be empty", { variant: 'error' })

        var Questiondata = {
            question: question.current.getContent(),
            optionA: optionA.current.getContent(),
            optionB: optionB.current.getContent(),
            optionC: optionC.current.getContent(),
            optionD: optionD.current.getContent(),
            correctAns: correctAnswer,
            serialNo: serialNo,
        }
        if (optionE.current.getContent()) {
            Questiondata.optionE = optionE.current.getContent()
        }
        if (solution.current.getContent()) {
            Questiondata.solution = solution.current.getContent()
        }
        setSubmitLoading(true)
        axios
            .post(api.createMcq, { ...Questiondata }, config)
            .then((response) => {
                const { status } = response
                if (status == 200) {
                    enqueueSnackbar("Question Created successfully", { variant: 'success' })
                    addMCQ(response.data._id)
                }
                setSubmitLoading(false)
                props.handleClose()
                props.handleSuccess()
            })
            .catch((error) => {
                enqueueSnackbar("Add new question failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function updateQuestion() {

        if (!question.current.getContent()) return enqueueSnackbar("Question is not allowed to be empty", { variant: 'error' })
        if (!optionA.current.getContent()) return enqueueSnackbar("Option A is not allowed to be empty", { variant: 'error' })
        if (!optionB.current.getContent()) return enqueueSnackbar("Option B is not allowed to be empty", { variant: 'error' })
        if (!optionC.current.getContent()) return enqueueSnackbar("Option C is not allowed to be empty", { variant: 'error' })
        if (!optionD.current.getContent()) return enqueueSnackbar("Option D is not allowed to be empty", { variant: 'error' })
        if (!correctAnswer) return enqueueSnackbar("Correct Answer is not allowed to be empty", { variant: 'error' })
        if (!serialNo) return enqueueSnackbar("Serial is not allowed to be empty", { variant: 'error' })

        var Questiondata = {
            question: question.current.getContent(),
            optionA: optionA.current.getContent(),
            optionB: optionB.current.getContent(),
            optionC: optionC.current.getContent(),
            optionD: optionD.current.getContent(),
            correctAns: correctAnswer,
            serialNo: serialNo,
        }
        if (optionE.current.getContent()) {
            Questiondata.optionE = optionE.current.getContent()
        }
        if (solution.current.getContent()) {
            Questiondata.solution = solution.current.getContent()
        }
        setSubmitLoading(true)
        axios
            .put(`/admin/mcq-questions/${props.questionId}`, { ...Questiondata }, config)
            .then((response) => {
                setSubmitLoading(false)
                enqueueSnackbar(response.data, { variant: 'success' })
                props.handleClose()
                props.handleSuccess()
            })
            .catch((error) => {
                enqueueSnackbar("Update question failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        // TODO: Stop initial loading
        if (!props.open) {
            return
        }
        if (props.questionId) {
            fetchQuestionData(props.questionId)
        } else {
            setSerialNo(props?.defaultSerialNo)
        }
    }, [props.open])


    const appBar = (
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={props.handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Add or Update Exam
                </Typography>
                <LoadingButton
                    color="secondary"
                    onClick={handleSubmit}
                    loading={questionLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
                <LoadingButton
                    color="inherit"
                    sx={{ ml: 2 }}
                    onClick={handleReset}
                    loading={questionLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<RestoreIcon />}
                    variant="contained"
                >
                    Reset
                </LoadingButton>
            </Toolbar>
        </AppBar>
    )

    const formUi = (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
        >
            <Grid md={12}>
                <Typography sx={{ m: 2, color: 'green' }} variant="h4" align="center">{props.questionId ? "Update question" : "Create a new question"}</Typography>
            </Grid>

            <Grid sx={{ mt: 2, display: 'flex' }} item md={12}>
                <Grid item xs={12} md={3}>
                    <Typography variant="h4" align="center">Question: </Typography>
                </Grid>
                <Grid sx={{ ml: 2 }} item xs={12} md={8}>
                    <Editor
                        tinymceScriptSrc="https://cdn.rootsedulive.com/scripts/js/tinymce/tinymce.min.js"
                        onInit={(evt, editor) => (question.current = editor)}
                        initialValue={formData.question ? formData.question : ""}
                        init={{
                            height: 350,
                            width: 550,
                            menubar: false,
                            automatic_uploads: false,
                            file_picker_types: 'image',
                            file_picker_callback: (cb, _value, _meta) => filePickerCallBack(question.current, cb),
                            plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                            ],
                            external_plugins: {
                                tiny_mce_wiris:
                                    "https://cdn.jsdelivr.net/npm/@wiris/mathtype-tinymce4@7.24.0/plugin.min.js",
                            },
                            toolbar:
                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image",
                            content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                    />
                </Grid>
            </Grid>
            <Divider />

            <Grid sx={{ mt: 2, display: 'flex' }} item md={12}>
                <Grid item xs={12} md={3}>
                    <Typography variant="h4" align="center">Option A: </Typography>
                </Grid>
                <Grid sx={{ ml: 2 }} item xs={12} md={8}>
                    <Editor
                        tinymceScriptSrc="https://cdn.rootsedulive.com/scripts/js/tinymce/tinymce.min.js"
                        onInit={(evt, editor) => (optionA.current = editor)}
                        initialValue={formData.optionA ? formData.optionA : ""}
                        init={{
                            height: 350,
                            width: 550,
                            menubar: false,
                            automatic_uploads: false,
                            file_picker_types: 'image',
                            file_picker_callback: (cb, _value, _meta) => filePickerCallBack(optionA.current, cb),
                            plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                            ],
                            external_plugins: {
                                tiny_mce_wiris:
                                    "https://cdn.jsdelivr.net/npm/@wiris/mathtype-tinymce4@7.24.0/plugin.min.js",
                            },
                            toolbar:
                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image",
                            content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                    />
                </Grid>
            </Grid>
           

            <Grid sx={{ mt: 2, display: 'flex' }} item md={12}>
                <Grid item xs={12} md={3}>
                    <Typography variant="h4" align="center">Option B: </Typography>
                </Grid>
                <Grid sx={{ ml: 2 }} item xs={12} md={8}>
                    <Editor
                        tinymceScriptSrc="https://cdn.rootsedulive.com/scripts/js/tinymce/tinymce.min.js"
                        onInit={(evt, editor) => (optionB.current = editor)}
                        initialValue={formData.optionB ? formData.optionB : ""}
                        init={{
                            height: 350,
                            width: 550,
                            menubar: false,
                            automatic_uploads: false,
                            file_picker_types: 'image',
                            file_picker_callback: (cb, _value, _meta) => filePickerCallBack(optionB.current, cb),
                            plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                            ],
                            external_plugins: {
                                tiny_mce_wiris:
                                    "https://cdn.jsdelivr.net/npm/@wiris/mathtype-tinymce4@7.24.0/plugin.min.js",
                            },
                            toolbar:
                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image",
                            content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                    />
                </Grid>
            </Grid>


            <Grid sx={{ mt: 2, display: 'flex' }} item md={12}>
                <Grid item xs={12} md={3}>
                    <Typography variant="h4" align="center">Option C: </Typography>
                </Grid>
                <Grid sx={{ ml: 2 }} item xs={12} md={8}>
                    <Editor
                        tinymceScriptSrc="https://cdn.rootsedulive.com/scripts/js/tinymce/tinymce.min.js"
                        onInit={(evt, editor) => (optionC.current = editor)}
                        initialValue={formData.optionC ? formData.optionC : ""}
                        init={{
                            height: 350,
                            width: 550,
                            menubar: false,
                            automatic_uploads: false,
                            file_picker_types: 'image',
                            file_picker_callback: (cb, _value, _meta) => filePickerCallBack(optionC.current, cb),
                            plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                            ],
                            external_plugins: {
                                tiny_mce_wiris:
                                    "https://cdn.jsdelivr.net/npm/@wiris/mathtype-tinymce4@7.24.0/plugin.min.js",
                            },
                            toolbar:
                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image",
                            content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                    />
                </Grid>
            </Grid>


            <Grid sx={{ mt: 2, display: 'flex' }} item md={12}>
                <Grid item xs={12} md={3}>
                    <Typography variant="h4" align="center">Option D: </Typography>
                </Grid>
                <Grid sx={{ ml: 2 }} item xs={12} md={8}>
                    <Editor
                        tinymceScriptSrc="https://cdn.rootsedulive.com/scripts/js/tinymce/tinymce.min.js"
                        onInit={(evt, editor) => (optionD.current = editor)}
                        initialValue={formData.optionD ? formData.optionD : ""}
                        init={{
                            height: 350,
                            width: 550,
                            menubar: false,
                            automatic_uploads: false,
                            file_picker_types: 'image',
                            file_picker_callback: (cb, _value, _meta) => filePickerCallBack(optionD.current, cb),
                            plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                            ],
                            external_plugins: {
                                tiny_mce_wiris:
                                    "https://cdn.jsdelivr.net/npm/@wiris/mathtype-tinymce4@7.24.0/plugin.min.js",
                            },
                            toolbar:
                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image",
                            content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                    />
                </Grid>
            </Grid>

            <Grid sx={{ mt: 2, display: 'flex' }} item md={12}>
                <Grid item xs={12} md={3}>
                    <Typography variant="h4" align="center">Option E: </Typography>
                </Grid>
                <Grid sx={{ ml: 2 }} item xs={12} md={8}>
                    <Editor
                        tinymceScriptSrc="https://cdn.rootsedulive.com/scripts/js/tinymce/tinymce.min.js"
                        onInit={(evt, editor) => (optionE.current = editor)}
                        initialValue={formData.optionE ? formData.optionE : ""}
                        init={{
                            height: 350,
                            width: 550,
                            menubar: false,
                            automatic_uploads: false,
                            file_picker_types: 'image',
                            file_picker_callback: (cb, _value, _meta) => filePickerCallBack(optionE.current, cb),
                            plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                            ],
                            external_plugins: {
                                tiny_mce_wiris:
                                    "https://cdn.jsdelivr.net/npm/@wiris/mathtype-tinymce4@7.24.0/plugin.min.js",
                            },
                            toolbar:
                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image",
                            content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                    />
                </Grid>
            </Grid>


            <Grid sx={{ mt: 2, display: 'flex' }} item md={12}>
                <Grid item xs={12} md={3}>
                    <Typography variant="h4" align="center">Solution: </Typography>
                </Grid>
                <Grid sx={{ ml: 2 }} item xs={12} md={8}>
                    <Editor
                        tinymceScriptSrc="https://cdn.rootsedulive.com/scripts/js/tinymce/tinymce.min.js"
                        onInit={(evt, editor) => (solution.current = editor)}
                        initialValue={formData.solution ? formData.solution : ""}
                        init={{
                            height: 350,
                            width: 550,
                            menubar: false,
                            automatic_uploads: false,
                            file_picker_types: 'image',
                            file_picker_callback: (cb, _value, _meta) => filePickerCallBack(solution.current, cb),
                            plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                            ],
                            external_plugins: {
                                tiny_mce_wiris:
                                    "https://cdn.jsdelivr.net/npm/@wiris/mathtype-tinymce4@7.24.0/plugin.min.js",
                            },
                            toolbar:
                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image",
                            content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                    />
                </Grid>
            </Grid>


            <Grid sx={{ mt: 2, display: 'flex', justifyContent: 'center' }} item md={12}>
                <Grid sx={{ m: 1 }} item md={6}>
                    <FormControl sx={{ minWidth: '200px' }}>
                        <InputLabel id="demo-simple-select-label">Correct Answer</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={correctAnswer}
                            label="Correct Answer"
                            onChange={handleChangeCorrectAnswer}
                        >
                            <MenuItem defaultChecked={true} disabled value={""}>Select Correct Answer </MenuItem>
                            <MenuItem value={'A'}>A</MenuItem>
                            <MenuItem value={'B'}>B</MenuItem>
                            <MenuItem value={'C'}>C</MenuItem>
                            <MenuItem value={'D'}>D</MenuItem>
                            <MenuItem value={'E'}>E</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid sx={{ m: 1 }} item md={6}>
                    <TextField
                        sx={{ minWidth: '200px' }}
                        onChange={(e) => setSerialNo(e.target.value)}
                        id="outlined-number"
                        label="Serial NO."
                        value={serialNo}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>

            <Grid sx={{ mt: 2, display: 'flex', justifyContent: 'center' }} item md={12}>
                <LoadingButton
                    sx={{ width: '200px' }}
                    color="secondary"
                    onClick={handleSubmit}
                    loading={questionLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
            </Grid>
        </Grid>
    )

    return (
        <Dialog fullScreen disableEnforceFocus open={Boolean(props.open)} onClose={props.handleClose}>
            {appBar}
            {(questionLoading || submitLoading) ? <BasicCircularProgress /> :
                <Box component={Paper} eleva sx={{ m: 2, p: 4 }}>
                    {formUi}
                </Box>}
        </Dialog>
    )
}

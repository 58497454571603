import React, { useState } from 'react'
import { useSnackbar } from "notistack"

import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from "@mui/material/TextField"
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import ExcelToUploadPhone from "../BulkUserCreateOrder/ExcelToUploadPhone"
import GoalFilter from "../../../Components/Filters/GoalFilter"
import SubscriptionFilter from "../../../Components/Filters/SubscriptionFilter"
import axios from "../../../axios"
import api from "../../../api"

const FilterTitleByMode = {
    SubscriptionCourse: "Select Subscription",
    FreeCourse: "Select Goal"
}
const ExplorerMode = {
    SubscriptionCourse: "SubscriptionCourse",
    FreeCourse: "FreeCourse"
}


export default function BulkUserSubscriptionOrder() {

    const [rejectedUser, setRejectedUser] = React.useState([])

    const [explorerMode, setExplorerMode] = useState(ExplorerMode.SubscriptionCourse)
    const [selectedGoal, setSelectedGoal] = useState('')
    const [selectedSubscription, setSelectedSubscription] = useState('')

    const [formData, setFormData] = React.useState(
        {
            users : [],
            numberOfDay : 0,
        })

    const { enqueueSnackbar } = useSnackbar()
    const config = { headers: { "x-auth-token": localStorage.getItem("user") } }

    const handleMergeUser = (users) => { setFormData({ ...formData, users: users }) }

    const handleSubmit = async () => {
        if (formData.users.length == undefined || formData.users?.length <= 0) return enqueueSnackbar("User is not allowed to be empty", { variant: "warning" })
        if (!formData.numberOfDay) return enqueueSnackbar("Number of day is not allowed to be empty", { variant: "warning" })
        if(!selectedSubscription) return enqueueSnackbar("Subscription is not allowed to be empty", { variant: "warning" })
        try {
            const response = await axios.post(api.subscriptionIncreaseBulkUserOrder, { ...formData, subscriptionId : selectedSubscription }, config)
            enqueueSnackbar("" + response.data.message, { variant: "success" })
        } catch (error) {
            enqueueSnackbar("" + error.response.data, { variant: "error" })
        }
    }

    const goalSelector = (
        <GoalFilter
            selectedGoal={selectedGoal}
            handleGoalSelection={(goalId) => {
                setSelectedGoal(goalId)
            }}
        />
    )

    const subscriptionSelector = (
        <SubscriptionFilter
            selectedSubscription={selectedSubscription}
            handleSubscriptionSelection={(subscriptionId) => {
                setSelectedSubscription(subscriptionId)
            }}
        />
    )

    const filterBox = (
        <Card> 
            <CardHeader
                title={FilterTitleByMode[explorerMode]}

            />
            <CardContent>
                {explorerMode === "SubscriptionCourse" && subscriptionSelector}
                {explorerMode === "FreeCourse" && goalSelector}
            </CardContent>
        </Card>
    )

    const form = (
        <Paper variant="outlined" sx={{ m: 2, p: 4, width: { md: 600, xs: 300 } }} square>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                rowSpacing={4}
                columnSpacing={4}
            >
                <Grid item md={12} xs={12}>
                   {filterBox}
                </Grid>

                <Grid item md={12} xs={12}>
                    <TextField
                        sx={{ width: '100%' }}
                        required
                        name="numberOfDay"
                        value={formData.numberOfDay}
                        onChange={(e) => setFormData({ ...formData, numberOfDay: e.target.value })}
                        type='number'
                        id="outlined-required"
                        label="Number Of Day"
                        defaultValue={0}
                    />
                </Grid>

                <Grid item md={12}>
                    <ExcelToUploadPhone handleMergeUser={(users) => handleMergeUser(users)} />
                </Grid>

                <Grid sx={{ display: 'flex', justifyContent: 'center', width: '100%' }} item md={12}>
                    <Button onClick={handleSubmit} sx={{ width: '95%', color: 'white', textTransform: 'none' }} variant="contained">Submit</Button>
                </Grid>

            </Grid>
        </Paper>
    )

    return (
        <Box elevation={2} component={Paper} sx={{ p: 2 }}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Typography sx={{ color: '#0000009e' }} fontWeight={800} variant='h5' gutterBottom align='center'>Bulk Subscription Limit Increase</Typography>
                {form}
            </Grid>
        </Box>
    )
}

import * as React from 'react';

/* @MUI COMPONENT */
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { green } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CardMedia from '@mui/material/CardMedia';

import moment from "moment"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserPaymentdetails({ isShowUserDetails, userPaymentDetails, handlecloseUserDetails }) {

    const handleClose = () => {
        handlecloseUserDetails()
    };

    const paymentCard = (item) => (
        <Card elevation={8} sx={{ maxWidth: 375 }}>
            <CardMedia
                component="img"
                height="200"
                image={"https://cdn.rootsedulive.com/" + item?.paymentPlanId?.subscriptionPlan?.subscriptions[0]?.banner}
                alt="subscription"
            />
            <CardContent>
                <Typography sx={{ color: green[400] }} gutterBottom variant="h6" component="div">
                    Subscription Title : {item?.paymentPlanId?.subscriptionPlan?.subscriptions[0]?.title}
                </Typography>
                <Typography gutterBottom variant="body1" component="div">
                    Transaction ID : {item.transactionId}
                </Typography>
                <Typography gutterBottom variant="body1" component="div">
                    Payment Amount : {item.paymentAmount}
                </Typography>
                <Typography gutterBottom variant="body1" component="div">
                    Store Amount : {item.storeAmount}
                </Typography>
                <Typography gutterBottom variant="body1" component="div">
                    Status : {item.status}
                </Typography>
                <Typography gutterBottom variant="body1" component="div">
                    Payment Date : {moment(item.paymentDate).format("YYYY-MM-DD HH:mm:ss A")}
                </Typography>
                <Typography gutterBottom variant="body1" component="div">
                    Coupon  : {item.coupon && item.coupon}
                </Typography>
            </CardContent>
        </Card>
    )

    const userCard = (
        <Card elevation={8} sx={{ m: 4, width: 375, p: 4, display : 'contents' }}>
            <CardContent>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={4}>
                    <Avatar sx={{ bgcolor: green[500] }}>
                        <AccountCircleIcon />
                    </Avatar>
                    <Typography gutterBottom align="center" variant="h6">
                        Name : {userPaymentDetails[0]?.userId.name}
                    </Typography>
                    <Typography gutterBottom align="center" variant="h6">
                        Email : {userPaymentDetails[0]?.userId.email}
                    </Typography>
                    <Typography gutterBottom align="center" variant="h6">
                        Phone : {userPaymentDetails[0]?.userId.phone}
                    </Typography>
                </Grid>
            </CardContent>
        </Card>
    )

    const data = (
        <Box sx={{ flexGrow: 1, width: '100%', p: 4 }}>
            <Grid container justifyContent="start" alignItems="center" spacing={2}>
                <Grid xs={12}>
                    {userCard}
                </Grid>
                {userPaymentDetails && userPaymentDetails?.map((item, index) =>
                    <Grid key={index} item xs={12} md={3}>
                        {paymentCard(item)}
                    </Grid>
                )}
            </Grid>
        </Box>
    )

    return (
        <div>
            <Dialog
                fullScreen
                open={isShowUserDetails}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            User Payment Details
                        </Typography>
                    </Toolbar>
                </AppBar>
                {userPaymentDetails && data}
            </Dialog>
        </div>
    );
}


import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import axios from '../../../../axios';
import LiveClasCard from "./LiveClassCard"
import LiveClassSkeleton from "./LiveClassSkeleton"
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import EmptyComponent from '../../../../Components/EmptyComponent';

export default function LiveClassList() {
  const [data, setData] = useState({ count: 0, results: [], loading: true, currentPage: 1 })
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const config = {
    headers: {
      "x-auth-token": localStorage.getItem("user"),
    }
  }


  useEffect(() => {
    fetchRoom()
  }, [data.currentPage,refresh])


  const fetchRoom = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`/admin/liveClass-room?pageNo=${data.currentPage - 1}&row=10`, config)
      setData({ ...data, count: response?.data?.count, results: response?.data?.result?.liveClassRooms })
      setLoading(false)
    } catch (error) {

    }
  }

  const handleChangePage = (event, value) => {
    setData({ ...data, currentPage: value })
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {!loading && data?.results.map((item, index) => (
          <Grid item xs={2} sm={4} md={4} key={index}>
            <LiveClasCard
              expiration={item?.expiration}
              name={item?.name}
              roomId={item?._id}
              hosts={item?.hosts}
              isActive={item?.isActive}
              isLive={item?.isLive}
              type={item?.type}
              handleRefreshData={()=>setRefresh(prevState=>prevState+1)}
            />
          </Grid>
        ))}

        {loading && Array.from(Array(10)).map((_, index) => (
          <Grid item xs={2} sm={4} md={4} key={index}>
            <LiveClassSkeleton />
          </Grid>
        ))}

        <Grid container sx={{ display: 'block' }} item md={12}>
          {!loading && data?.results.length > 0 &&
            <Pagination
              page={data.currentPage}
              onChange={handleChangePage}
              sx={{ display: 'block' }}
              count={Math.ceil(data.count / 10)}
              variant="outlined"
              shape="rounded"
            />}
          {loading && <Skeleton variant="rectangular" width={"100%"} height={30} />}

          {!loading && data?.results.length === 0 && 
             <EmptyComponent title={"No Live Class Found"} />
            // <Typography sx={{color : '#00000073', fontWeight : '50'}} align="center" variant="h3">No records found </Typography>
          }
        </Grid>
      </Grid>
    </Box>
  )
}

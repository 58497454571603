import React, { useEffect, useState } from "react"
import "../../../Css/root.css"
import axios from "../../../axios"
import api from "../../../api"
import AddIcon from '@mui/icons-material/Add'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import FolderIcon from '@mui/icons-material/Folder'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconLabelListSkeleton from "../../../Components/IconLabelListSkeleton"
import EditDeleteMenu from "../../../Components/EditDeleteMenu"
import ChapterAddUpdateDialog from "./ChapterAddUpdateDialog"
import SimpleDeleteModal from "../../../Components/SimpleDeleteModal"
import { useSnackbar } from "notistack"

const TAG = "ChapterList"
const generateKey = (pre) => {
    return `${TAG}_${ pre }_${ new Date().getTime() }`
}

export default function ChapterList(props) {
    const [chapters, setChapters] = useState([])
    const [chapterLoading, setChapterLoading] = useState(false)

    const [dialogChapterId, setDialogChapterId] = useState('')
    const [openChapterDialog, setOpenChapterDialog] = useState(false)

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deleteModalChapterId, setDeleteModalChapterId] = useState('')

    const [refreshKey, setRefreshKey] = useState(0)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user")
        }
    }

    const refresh = () => {
        props.handleChapterSelection('')
        setRefreshKey(prevKey => prevKey + 1)
    }

    const handleChapterSelection = (event) => {
        props.handleChapterSelection(event.currentTarget.getAttribute('value'))
    }

    const handleAddOrEditPressed = (chapterId) => {
        setDialogChapterId(chapterId)
        setOpenChapterDialog(true)
    }

    const handleDeletePressed = (chapterId) => {
        setDeleteModalChapterId(chapterId)
        setOpenDeleteModal(true)
    }

    async function getChaptersBySubject(subjectId) {
        setChapterLoading(true)
        axios
            .get(api.getFilterChapters + subjectId, config)
            .then((response) => {
                setChapters(response.data)
                setChapterLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Chapter data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function deleteChapter(chapterId) {
        axios
            .delete(api.deleteChapter + chapterId, config)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
                refresh()
            })
            .catch((error) => {
                enqueueSnackbar("Delete chapter failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        getChaptersBySubject(props.subjectId)
    },[props.subjectId, refreshKey])

    const chapterItems = (item) => {
        return (
            <ListItem
                key={item._id}
                secondaryAction={
                    <EditDeleteMenu
                        value={item._id}
                        editAction={handleAddOrEditPressed}
                        deleteAction={handleDeletePressed}
                    />
                }
                disablePadding
            >
                <ListItemButton
                    selected={props.selectedChapter === item._id}
                    value={item._id}
                    onClick={handleChapterSelection}
                >
                    <ListItemIcon>
                        <FolderIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={item.title}
                    />
                </ListItemButton>
            </ListItem>
        )
    }

    const chapterItemsSkeleton = [1, 2, 3, 4, 5].map((value) => <IconLabelListSkeleton key={value} />)

    return (
        <Card sx={{ width: "20vw" }}>
            <CardHeader
                title="Chapters"
                action={
                    <Tooltip title="Add New Chapter">
                        <IconButton aria-label="add" onClick={(_event) => handleAddOrEditPressed('')}>
                            <AddIcon color="primary" sx={{ width: 30, height: 30 }} />
                        </IconButton>
                    </Tooltip>
                }
            />

            <CardContent>
                <List>
                    {chapterLoading ? chapterItemsSkeleton : chapters.map(chapterItems)}
                </List>
            </CardContent>
            <ChapterAddUpdateDialog
                key={generateKey("chapter-dialog")}
                subjectId={props.subjectId}
                chapterId={dialogChapterId}
                open={openChapterDialog}
                handleClose={() => setOpenChapterDialog(false)}
                handleSuccess={refresh}
            />
            <SimpleDeleteModal
                title="Delete Chapter"
                open={openDeleteModal}
                handleClose={() => {
                    setDeleteModalChapterId('')
                    setOpenDeleteModal(false)
                }}
                handleDelete={() => deleteChapter(deleteModalChapterId)}
            />
        </Card>
    )
}
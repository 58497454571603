import React from 'react';
import Button from '@mui/material/Button';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from "notistack"
import axios from "../../axios";

export default function OrderExportExcel({ selectedSubscription, selectedGoal }) {

    const [loading, setLoading] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    function dateConverter(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = `Order_Subscription_${selectedSubscription}`


    const [token, setToken] = React.useState(localStorage.getItem("user"));

    const fetchData = async () => {
        setLoading(true);
        const searchUrl = `/admin/orders/get-user-by-subscription?subscriptionId=${selectedSubscription}`
        try {
            const response = await axios.get(searchUrl, {
                headers: {
                    "x-auth-token": token,
                }
            })
            return response.data;
        } catch (error) {
        }
    }

    const exportToCSV = async () => {

        if (!selectedSubscription) return enqueueSnackbar("Subscription id is not allowed to be empty", { variant: 'error' });
        var importData = await fetchData();
        var excelData = [];
        for (var index in importData) {
            excelData.push({
                Name: importData[index]?.name,
                Email: importData[index]?.email,
                Phone: importData[index]?.phone,
                SubscriptionEndDate: dateConverter(importData[index]?.subscriptions.endDate),
                LastPaymentDate: dateConverter(importData[index]?.subscriptions.lastPaymentDate),
            });
        }
        setLoading(false);
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return <LoadingButton
        color="secondary"
        onClick={() => exportToCSV()}
        loading={loading}
        loadingPosition="start"
        variant="contained"
        sx={{width : '100%', mt : 6}}
    >
        EXPORT TO EXCEL
    </LoadingButton>
}
